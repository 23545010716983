import React from 'react';
import {Button, Divider} from 'antd';
import styles from './index.scss';
import {Link} from "react-router-dom";
import {loginUserStore} from "../../store/Login/LoginUserStore";
import commonStyles from '../../../src/styles/common.scss';
import appStyles from '../../assets/css/app.scss';
import {redirect} from "../../../src/common/Router/routerCommon";

export default class KixGbcGlobalHeader extends React.Component<any, any> {

  render() {
    const {
      isMobile, logo,
    } = this.props;

    return (
      <>
        {isMobile && (
          [
            (
              <Link to="/" className={styles.logo} key="logo">
                <img src={logo} alt="logo" width="32"/>
              </Link>
            ),
            <Divider type="vertical" key="line"/>,
          ]
        )}
        <div className={styles.logo} key="logo" id={"header"}>
          <div className={commonStyles.fCenter} style={{"paddingTop": "20px"}}>
            <a href={"https://www.kansai-airport.or.jp/kix-gbc/"}><img src={logo} alt="logo" style={{width: 280}}/></a>
          </div>
        </div>

        {/*<nav id="fixedBox">*/}
          {/*<ul className="clearfix">*/}
            {/*<li><a target={"blank"} href="https://www.kansai-airport.or.jp/kix-gbc/">ホーム</a></li>*/}
            {/*<li className="now"><a target={"blank"}*/}
                                   {/*href="https://www.kansai-airport.or.jp/kix-gbc/service/">サービスのご案内</a></li>*/}
            {/*<li className="long"><a target={"blank"}*/}
                                    {/*href="https://www.kansai-airport.or.jp/kix-gbc/pamph/">パンフレット・会員規約</a></li>*/}
            {/*<li><a target={"blank"} href="https://www.kansai-airport.or.jp/kix-gbc/register/">ご入会方法</a></li>*/}
          {/*</ul>*/}
        {/*</nav>*/}

        {/*<div className="pankuzubox">*/}
          {/*<p className="pankuzu">*/}
            {/*<a href="../" style={{marginRight: "5px"}}>*/}
              {/*<img src={iconHome} alt="ホーム" width="11" height="10" title="ホーム"/>*/}
            {/*</a>&gt; サービスのご案内*/}
          {/*</p>*/}
        {/*</div>*/}

        <div className={`title ${commonStyles.fCenter}`}>
          <div style={{width: 900}} className={commonStyles.f}>
            <h1 style={{margin: 0}}>会員専用ページ</h1>
            <div className={`${commonStyles.fItemRight}`}>
              <Button onClick={() => redirect("/my_page")} className={`${appStyles.buttonGray} ${commonStyles.mr5}`}>
                マイページ
              </Button>
              <Button onClick={() => redirect("/")} className={`${appStyles.buttonGray} ${commonStyles.mr5}`}>
                TOP
              </Button>
              {loginUserStore && loginUserStore.loginUserInfo && loginUserStore.loginUserInfo.id
                ? <Button onClick={loginUserStore.logout} className={`${appStyles.buttonGray}`}>
                  ログアウト
                </Button>
                : ""
              }

            </div>
          </div>
        </div>

      </>
    );
  }
}
