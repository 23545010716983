import {action, observable, runInAction} from "mobx";
import {toDateStr} from "../../src/utils/datetime/moment";
import moment from "moment";
import {BASE_PATH, LoginIndexResponse} from "../../src/swagger";
import {redirect} from "../../src/common/Router/routerCommon";
import {message, Modal} from "antd";
import {WrappedFormUtils} from "antd/lib/form/Form";
import * as React from "react";
import {HttpUtils} from "../../src/utils/Http/HttpUtils";
import {timeStore} from "../store/TimeStore";
import {SwitchPromise} from "../../src/utils/Promise/SwitchPromise";
import {loginUserStore} from "../store/Login/LoginUserStore";
import json = HttpUtils.json;

export class FastlaneService {
  tokens: any = {};
  initPromise = new SwitchPromise();

  constructor(public formUtils: WrappedFormUtils, public isLane: number) {

    loginUserStore.getLoginUser().then((loginUserInfo: LoginIndexResponse) => {
      if (loginUserInfo.id) {
        let userId = loginUserInfo.id;
        let tokens = localStorage.getItem(`tokens.${userId}`);
        this.tokens = JSON.parse(tokens || "{}");
        this.initPromise.resolve();
      }
    });

  }

  @observable
  isModalVisible = false;

  @observable
  isFastLanePreConfirmModalVisible = false;

  @observable
  isLoungePreConfirmModalVisible = false;

  @observable
  isCancelModalVisible = false;

  @observable
  isLoungeErrorVisible = false;

  @observable
  remaining = 0;

  getFlightDate = async (isLane: number) => {
    await this.initPromise.promise;

    let flightDate = toDateStr(moment(timeStore.time));
    let flightDate2 = toDateStr(moment(timeStore.time).add({"day": 1}));
    let flightDate3 = toDateStr(moment(timeStore.time).add({"day": 2}));
    if (this.tokens[isLane + "-" + toDateStr(flightDate)!]) {
      return flightDate;
    }
    if (this.tokens[isLane + "-" + toDateStr(flightDate2)!]) {
      return flightDate2;
    }
    if (this.tokens[isLane + "-" + toDateStr(flightDate3)!]) {
      return flightDate3;
    }
    return flightDate;
  };

  getToken = async (isLane: number) => {
    await this.initPromise.promise;

    let flightDate = toDateStr(moment(timeStore.time));
    let flightDate2 = toDateStr(moment(timeStore.time).add({"day": 1}));
    let flightDate3 = toDateStr(moment(timeStore.time).add({"day": 2}));
    return this.tokens[isLane + "-" + toDateStr(flightDate)!] || this.tokens[isLane + "-" + toDateStr(flightDate2)!] || this.tokens[isLane + "-" + toDateStr(flightDate3)!];
  };

  redirectInquiryOrConfirm = async () => {
    await this.initPromise.promise;

    this.formUtils.validateFields(async (err, _values) => {
      if (!err) {
        let body = {
          flight_date: "",
          is_lane: 1,
          token: "",
        };
        body.flight_date = (await this.getFlightDate(1))!.replace(/\-/g, "");
        body.token = await this.getToken(1);
        let lane1Check = json(BASE_PATH + "/check_permit", "POST", body);

        body.is_lane = 2;
        body.flight_date = (await this.getFlightDate(2))!.replace(/\-/g, "");
        body.token = await this.getToken(2);
        let lane2Check = json(BASE_PATH + "/check_permit", "POST", body);

        Promise.all([lane1Check, lane2Check]).then((result: any) => {
          let check: any;
          let anotherLaneCheck: any;
          if (this.isLane == 1) {
            check = result[0];
            anotherLaneCheck = result[1];
          } else {
            check = result[1];
            anotherLaneCheck = result[0];
          }

          if (check.content.result.code == 1) {
            if (check.content.value.valid_period_start) {
              runInAction(() => {
                this.isModalVisible = false;
              });
              window.scrollTo(0, 0);

              redirect(`/display_permit?flight_date=${encodeURIComponent(check.content.value.valid_period_start!)}&is_lane=${this.isLane}`);
            } else if (anotherLaneCheck.content.value.valid_period_start) {
              runInAction(async () => {
                this.isModalVisible = true;
                this.remaining = check.content.value.remaining;
              });
            } else {
              if (this.isLane == 1) {
                runInAction(() => {
                  this.isFastLanePreConfirmModalVisible = true;
                });
                // redirect(`/use_fastlane?is_lane=${this.isLane}`);
              } else {
                runInAction(() => {
                  this.isLoungePreConfirmModalVisible = true;
                });
              }
            }
          } else if (check.content.result.status == 2) {
            redirect(`/permit_error?is_lane=${this.isLane}`);
          } else {
            message.error(check.content.result.message);
          }
        }).catch((e: any) => {
          console.log(e);
          message.error("通信に失敗しました。もう一度お試し下さい。");
        });
      }
    });
  };

  @action
  handleConfirm = async (flightDateParam?: string) => {
    await this.initPromise.promise;

    this.formUtils.validateFields(async (err, _values) => {
      if (!err) {
        let flightDate = toDateStr(flightDateParam);
        let token = this.tokens[this.isLane + "-" + toDateStr(flightDate)!];
        if (!flightDate) {
          flightDate = await this.getFlightDate(this.isLane);
          token = await this.getToken(this.isLane);
        }
        let body = {
          flight_date: flightDate!.replace(/\-/g, ""),
          is_lane: this.isLane,
          token: token,
        };
        json(BASE_PATH + "/check_permit", "POST", body).then((res: any) => {
          const json = res.content;
          if (json.value.valid_period_start) {
            runInAction(() => {
              this.isModalVisible = false;
            });
            if (json.result.code == 1) {
              window.scrollTo(0, 0);
              redirect(`/display_permit?flight_date=${encodeURIComponent(flightDate!)}&is_lane=${this.isLane}`);
            } else {
              message.error(json.result.message);
            }
          } else {
            runInAction(async () => {
              this.isModalVisible = true;
              this.remaining = json.value.remaining;
            });
          }
        }).catch((e: any) => {
          console.log(e);
          message.error("通信に失敗しました。もう一度お試し下さい。");
        });
      }
    });
  };

  @action
  handleSubmit = async () => {
    await this.initPromise.promise;

    this.formUtils.validateFields(async (err, values) => {
      if (!err) {
        if (!values.flight_date) {
          // console.log((this.isLane == 1 ? 2 : 1) + "-" + toDateStr(flightDate)!);
          let body = {
            flight_date: (await this.getFlightDate(this.isLane == 1 ? 2 : 1))!.replace(/\-/g, ""),
            is_lane: this.isLane == 1 ? 2 : 1,
            token: await this.getToken(this.isLane == 1 ? 2 : 1),
          };
          let inquiry = await json(BASE_PATH + "/check_permit", "POST", body);
          values.flight_date = inquiry.content.value.valid_period_start;
          values.dest = inquiry.content.value.dest;
          values.flight = inquiry.content.value.flightName;
          values.class = inquiry.content.value.flightClass;
        }

        let body = {
          flight_date: toDateStr(values.flight_date)!.replace(/\-/g, ""),
          is_lane: this.isLane,
          dest: values.dest,
          flight: values.flight,
          class: values.class,
        };
        json(BASE_PATH + "/use_permit", "POST", body).then((res: any) => {
          const json = res.content;
          runInAction(() => {
            this.isModalVisible = false;
          });
          if (json.result.code == 1) {
            this.tokens[this.isLane + "-" + toDateStr(values.flight_date)!] = json.value.token;
            loginUserStore.getLoginUser().then((loginUserInfo: LoginIndexResponse) => {
              let userId = loginUserInfo.id;
              localStorage.setItem(`tokens.${userId}`, JSON.stringify(this.tokens));
            });
            window.scrollTo(0, 0);
            redirect(`/display_permit?flight_date=${encodeURIComponent(toDateStr(values.flight_date)!)}&is_lane=${this.isLane}`);
          } else {
            if (json.result.status == 8) {
              runInAction(() => {
                this.isLoungeErrorVisible = true;
              });
            } else {
              message.error(json.result.message);
            }
          }
        }).catch((e) => {
          console.error(e);
          message.error("通信に失敗しました。もう一度お試し下さい。");
        });
      }
    });
  };
  @action
  handleCancel = async () => {
    await this.initPromise.promise;

    let flightDate = toDateStr(moment(timeStore.time));
    let flightDate2 = toDateStr(moment(timeStore.time).add({"day": 1}));
    let flightDate3 = toDateStr(moment(timeStore.time).add({"day": 2}));

    delete this.tokens[1 + "-" + toDateStr(flightDate)!];
    delete this.tokens[1 + "-" + toDateStr(flightDate2)!];
    delete this.tokens[1 + "-" + toDateStr(flightDate3)!];

    delete this.tokens[2 + "-" + toDateStr(flightDate)!];
    delete this.tokens[2 + "-" + toDateStr(flightDate2)!];
    delete this.tokens[2 + "-" + toDateStr(flightDate3)!];
    await loginUserStore.getLoginUser().then((loginUserInfo: LoginIndexResponse) => {
      let userId = loginUserInfo.id;
      localStorage.setItem(`tokens.${userId}`, JSON.stringify(this.tokens));
    });
    runInAction(() => this.isCancelModalVisible = false);
    redirect(`/`);

    // this.formUtils.validateFields((err, _values) => {
    //   if (!err) {
    //     let flightDate = toDateStr(moment(timeStore.time));
    //     if (getQueryParam("flight_date")!) {
    //       flightDate = toDateStr(moment(getQueryParam("flight_date")!.replace(/\-/g, ""), "YYYYMMDD"))!.replace(/\-/g, "");
    //     }
    //     let body = {
    //       flight_date: flightDate!.replace(/\-/g, ""),
    //       is_lane: this.isLane,
    //       token: this.tokens[this.isLane + "-" + toDateStr(flightDate)!],
    //     };
    //     json(BASE_PATH + "/cancel_permit", "POST", body).then((res: any) => {
    //       const json = res.content;
    //       runInAction(() => {
    //         this.isCancelModalVisible = false;
    //       });
    //       if (json.result.code == 1) {
    //         window.scrollTo(0, 0);
    //         redirect(`/`);
    //       } else {
    //         message.error(json.result.message);
    //       }
    //     }).catch((e: any) => {
    //       console.log(e);
    //       message.error("通信に失敗しました。もう一度お試し下さい。");
    //     });
    //   }
    // });
  };

  displayModal = () => {
    return <>
      <Modal
        title=""
        visible={this.isModalVisible}
        onOk={this.handleSubmit}
        onCancel={() => runInAction(() => this.isModalVisible = false)}
        okText="利用する"
        cancelText="利用しない"
      >
        <p>残り回数 <span style={{color: "red"}}>{this.remaining + ""}回</span> です。</p>
        <p>本当に利用しますか？</p>
        <br/>
        {this.isLane == 1
          ? <>
            <p>・FastLaneでご提示できる端末でご利用ください</p>
            <p>・複数回登録不可のため、直近で利用予定の便を登録ください。</p>
            <p>・許可証の有効期間は搭乗日から起算して３日間です。</p>
          </>
          : <>
            <p>・ラウンジを利用できるのは長距離便（直行便）ご利用者様限定です。</p>
            <p>・長距離便とは、アメリカ(本土)、カナダ、ヨーロッパ、ロシア（モスクワ）、中東地域、オーストラリア、ニュージーランド、ニューカレドニアへの直行便です。（チャーター便は適用外です。）</p>
            <p>・満席の場合等、ラウンジをご利用いただけない場合がございます。ご了承ください。</p>
            <p>・許可証の有効期間は搭乗日から起算して3日間です。</p>
          </>
        }
      </Modal>

      <Modal
        title=""
        visible={this.isFastLanePreConfirmModalVisible}
        onOk={() => redirect(`/use_fastlane?is_lane=${this.isLane}`)}
        onCancel={() => runInAction(() => this.isFastLanePreConfirmModalVisible = false)}
        okText="はい"
        cancelText="キャンセル"
      >
        <p>複数回登録不可のため、直近で利用予定の便をご登録ください</p>
        <p>許可証の有効期間は搭乗日から起算して３日間です。</p>
      </Modal>

      <Modal
        title=""
        visible={this.isLoungePreConfirmModalVisible}
        onOk={() => redirect(`/use_fastlane?is_lane=${this.isLane}`)}
        onCancel={() => runInAction(() => this.isLoungePreConfirmModalVisible = false)}
        okText="はい"
        cancelText="キャンセル"
      >
        <p>ラウンジを利用できるのは長距離便（直行便）ご利用者様限定です。</p>
        <p></p>
        <p>長距離便とは、アメリカ(本土)、カナダ、ヨーロッパ、ロシア（モスクワ）、中東地域、オーストラリア、ニュージーランド、ニューカレドニアへの直行便です。（チャーター便は適用外です。）</p>
        <p></p>
        <p>満席の場合等、ラウンジをご利用いただけない場合がございます。ご了承ください。</p>
        <p></p>
        <p>許可証の有効期間は搭乗日から起算して3日間です。</p>
      </Modal>

      <Modal
        title=""
        visible={this.isCancelModalVisible}
        onOk={this.handleCancel}
        onCancel={() => runInAction(() => this.isCancelModalVisible = false)}
        okText="キャンセルする"
        cancelText="キャンセルしない"
      >
        <p>キャンセルを実行すると現在表示している許可証が取り消され、次に許可証を表示する際には再度のご登録が必要です。（利用回数が1回減ります）</p>
        <p></p>
        <p>{this.isLane == 1 ? "ラウンジ" : "Fast Lane"}の許可証をご登録の場合は、{this.isLane == 1 ? "ラウンジ" : "Fast Lane"}の許可証も同時に取り消されます</p>
      </Modal>

      <Modal
        title=""
        visible={this.isLoungeErrorVisible}
        onOk={() => window.open("https://www.kansai-airport.or.jp/kix-gbc/service/long-haul.html", "_blank")}
        onCancel={() => runInAction(() => this.isLoungeErrorVisible = false)}
        okText="ラウンジ対象便はこちら"
        cancelText="閉じる"
      >
        <p>
          アンケートに入力された便名はラウンジご利用対象外です。
        </p>
      </Modal>

    </>
  };

}
