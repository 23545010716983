import {action, observable, runInAction} from 'mobx'
import {
  Companies,
  LoginApi,
  LoginIndexRequest,
  LoginIndexResponse,
  LoginLogoutRequest,
  LoginLogoutResponse
} from "../../../src/swagger";
import {adminApiWrapper} from "../../../src/common/Api/Admin/adminApiWrapper";
import {redirect} from "../../../src/common/Router/routerCommon";


export class LoginUserStore {
  @observable
  public loginUserInfo = new LoginIndexResponse();

  @observable
  public loading: boolean = false;

  constructor() {
    this.loginUserInfo.company = new Companies();
  }

  @action getLoginUser = () => {
    if (this.loginUserInfo && this.loginUserInfo.id) {
      // キャッシュがあればキャッシュを返す
      return Promise.resolve(this.loginUserInfo);
    }

    let api = new LoginApi();
    return adminApiWrapper(() => {
        return api.index(new LoginIndexRequest()).then((res: LoginIndexResponse) => {
          runInAction(() => {
            this.loginUserInfo = res;
            if (!this.loginUserInfo.company) {
              this.loginUserInfo.company = new Companies();
            }
          });
          return this.loginUserInfo;
        });
      }
      , "getLoginUser"
    );
  };

  logout = () => {
    let api = new LoginApi();
    return adminApiWrapper(() => {
        return api.logout(new LoginLogoutRequest()).then((_res: LoginLogoutResponse) => {
          runInAction(() => {
            this.loginUserInfo = new LoginIndexResponse();
          });
          redirect("/login");
        })
      }
      , "getLoginUser"
    );
  }
}

export const loginUserStore = new LoginUserStore();


