import React from 'react';
import DocumentTitle from 'react-document-title';
import {Layout} from 'antd';
import enquire from 'enquire.js';
import {LoginUserStore} from "../../store/Login/LoginUserStore";
import {inject, observer} from "mobx-react";
import commonStyles from '../../../src/styles/common.scss';

const {Content} = Layout;

interface BasicLayoutProps {
  loginUserStore?: LoginUserStore
  notLogout?: boolean
}

@inject("loginUserStore")
@observer
class KixGbcMemberSimpleLayout extends React.Component<BasicLayoutProps, {}> {
  isMobile: boolean = false;
  loginUserStore = this.props.loginUserStore!;

  constructor(props: BasicLayoutProps, context: any) {
    super(props, context);

    enquire.register('screen and (max-width: 767.99px)', {
      match: () => this.isMobile = true,
      unmatch: () => this.isMobile = false,
    });

    // ユーザ情報取得
    this.loginUserStore.getLoginUser().then(() => {
      if (!this.loginUserStore.loginUserInfo.id && !this.props.notLogout) {
        // this.loginUserStore.logout();
      }
    });
  }

  getPageTitle() {
    return "Global Business Club | 会員専用ページ";
  }

  render() {
    return (
      <DocumentTitle title={this.getPageTitle()}>
        <>
          <Content style={{height: '100%', background: "#00125E"}} className={commonStyles.fCenter}>
            <div style={{minHeight: 'calc(100vh - 260px)', width: 900}}>
              {this.props.children}
            </div>
          </Content>
        </>
      </DocumentTitle>
    );
  }
}

export default KixGbcMemberSimpleLayout;
