import {action, observable, runInAction} from 'mobx'
import {PaginationProps} from "antd/lib/pagination";
import {Pagination} from "../../../constants/common/Pagination";
import {InformationApi, InformationGetListRequest, InformationGetListResponse} from "../../../swagger";
import {adminApiWrapper} from "../../../common/Api/Admin/adminApiWrapper";
import {InformationGetListRowForm} from "../../../swagger/api";

export class InformationStore {
  @observable
  public loading: boolean = false;

  @observable.ref
  public list: InformationGetListRowForm[] = [];

  public pagination: PaginationProps = Pagination.defaultProps;
  public request: InformationGetListRequest = new InformationGetListRequest();

  @action
  getList() {
    let api = new InformationApi();
    this.request.pagination = {current: this.pagination.current!, pageSize: this.pagination.pageSize!};

    this.loading = true;
    return adminApiWrapper(() => {
        return api.getList(this.request).then((res: InformationGetListResponse) => {
          runInAction(() => {
            this.list = res.list!;
            this.pagination.total = res.total;
            this.loading = false;
          })
        });
      },
      "InformationGetList"
    );
  }
}


