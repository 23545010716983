import {action, observable, runInAction} from 'mobx'
import {AdminLoginApi, AdminLoginIndexResponse} from "../../swagger";
import {globalStore} from "../GlobalStore";
import {adminApiWrapper} from "../../common/Api/Admin/adminApiWrapper";


export class LoginUserStore {
  @observable
  public loginUserInfo = new AdminLoginIndexResponse();

  @observable
  public loading: boolean = false;

  @action
  getLoginUser() {
    // キャッシュがあればキャッシュを返す
    if (this.loginUserInfo && this.loginUserInfo.id) {
      return
    }

    let api = new AdminLoginApi();
    adminApiWrapper(() => {
        return api.index().then((res: AdminLoginIndexResponse) => {
          runInAction(() => {
            this.loginUserInfo = res
          })
        })
      }
      , "getLoginUser"
    );
  }

  @action
  logout() {
    globalStore.routing.history.push("/login");
  }
}

export const loginUserStore = new LoginUserStore()


