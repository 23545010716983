
export namespace RegexUtils {
  /**
   * 文字列の先頭から最後まで引数で指定した正規表現のみで構成されてることを示す正規表現を返す
   * @param {string} regexStr
   */
  export function all(regexStr: string) {
    return new RegExp(`^${regexStr}$`);
  }

  /**
   * 文字列の先頭から最後まで引数で指定した文字セット([] 内に指定するもの)のみで構成されてることを示す正規表現を返す
   * @param {string} regexStr
   */
  export function allCharGroup(regexStr: string) {
    return new RegExp(`^[${regexStr}]+$`);
  }
}
