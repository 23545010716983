import {EventEmitter} from "events";

/**
 * resolve, reject を任意のタイミングで切り替えることのできる Promise
 */
export class SwitchPromise {
  events = new EventEmitter();
  status = "ready";
  promise: Promise<any>;
  resetFunc: () => any = () => null;

  constructor() {
    this.reset();
  }

  reset() {
    this.status = "ready";
    this.events = new EventEmitter();
    this.promise = new Promise((resolve, reject) => {
      this.events.on("resolve", () => {
        this.status = "resolved";
        resolve();
      });
      this.events.on("reject", () => {
        this.status = "rejected";
        reject();
      });
    });
    this.resetFunc();
  }

  resolve() {
    this.events.emit("resolve");
  }

  reject() {
    this.events.emit("reject");
  }
}
