import {ValidationRule, WrappedFormUtils} from "antd/lib/form/Form";
import {RegexParts} from "../../Regex/RegexParts";
import {nullToString} from "../../optional/optional";
import {RegexUtils} from "../../Regex/RegexUtils";
import {UploadFile} from "antd/lib/upload/interface";

export namespace BaseValidations {
  export var required: ValidationRule = {required: true, message: "入力して下さい。"};
  export var notRequired: ValidationRule = {required: false, message: "入力して下さい。"};
  export var require = (flag = true): ValidationRule => flag ? required : notRequired;

  export var ascii: ValidationRule = {pattern: RegexUtils.allCharGroup(RegexParts.ascii), message: "半角英数で入力して下さい 。"};
  export var digit: ValidationRule = {pattern: RegexUtils.allCharGroup(RegexParts.digit), message: "半角数字で入力して下さい 。"};
  export var alphaDigit: ValidationRule = {pattern: RegexUtils.allCharGroup(RegexParts.alphaDigit), message: "半角英数字で入力して下さい 。"};
  export var jpnPostalCode: ValidationRule = {
    pattern: RegexUtils.all(RegexParts.jpnPostalCode),
    message: "郵便番号を７桁のハイフンなしの数字で入力してください 。"
  };
  export var phoneNum: ValidationRule = {pattern: RegexUtils.all(RegexParts.phoneNum), message: "電話番号の形式が正しくありません 。"};
  export var email: ValidationRule = {pattern: RegexUtils.all(RegexParts.email), message: "メールアドレスの形式が正しくありません 。"};
  export var url: ValidationRule = {pattern: RegexUtils.all(RegexParts.url), message: "URLの形式が正しくありません 。"};
  export var id: ValidationRule = {
    pattern: RegexUtils.allCharGroup("a-zA-Z0-9_\\-."),
    message: "半角英数及び - _ . の文字のみで入力して下さい 。"
  };

  // 住所のカナ入力等（全角カナ・英数字 + 空白 + 半角英数記号 + 基本的な全角記号）
  export var wideKanaWithBasicSymbols: ValidationRule = {
    pattern: RegexUtils.allCharGroup(`${RegexParts.wideKana}${RegexParts.widAlphaDigit}${RegexParts.SpacesWithWide}${RegexParts.ascii}、．・：？！゛゜＿～（）［］｛｝「」【】＋－＝`),
    message: "全角カナで入力してください 。"
  };

  export function min(size: number): ValidationRule {
    return ({validator: makeMinVlidation(size)});
  }

  export function max(size: number): ValidationRule {
    return ({validator: makeMaxVlidation(size)});
  }

  export function minLen(size: number): ValidationRule {
    return {min: size, message: `${size}文字以上で入力して下さい。`};
  }

  export function maxLen(size: number): ValidationRule {
    return {max: size, message: `${size}文字以下で入力して下さい。`};
  }

  export function sameWith(formObj: WrappedFormUtils, compareTarget = 'password', message = "パスワードが確認用欄と一致しません。"): ValidationRule {
    return ({validator: makeSameWithValidation(formObj, compareTarget), message});
  }

  // ファイルが一つ以上アップロードされているか
  export function requireUpload(files: UploadFile[]): ValidationRule {
    return {
      validator: (_rule: any, _value: string, callback: (error?: string) => void): void => {
        if (!files || !files.length) {
          callback('ファイルがアップロードされていません');
        } else {
          callback();
        }
      }
    };
  }

  export function password(): ValidationRule {
    return ({validator: makePasswoedVlidation()});
  };

}

function makeMinVlidation(size: number) {
  return (_rule: any, value: string, callback: (error?: string) => void): void => {
    if (isNaN(Number(value)) || Number(value) < Number(size)) {
      callback(size + "以上で入力してください。");
    } else {
      callback();
    }
  }
}

function makeMaxVlidation(size: number) {
  return (_rule: any, value: string, callback: (error?: string) => void): void => {
    if (isNaN(Number(value)) || Number(value) > Number(size)) {
      callback(size + "以下で入力してください。");
    } else {
      callback();
    }
  }
}

// 確認用入力を入力欄と比較
function makeSameWithValidation(formObj: WrappedFormUtils, compareTarget: string) {
  return (_rule: any, value: string, callback: (error?: string) => void): void => {
    const compareValue = formObj.getFieldValue(compareTarget);
    if (nullToString(value) !== nullToString(compareValue)) {
      callback('入力した値が間違っています。');
    } else {
      callback();
    }
  }
}

function makePasswoedVlidation(){
  return (_rule: any, value: string, callback: (error?: string) => void): void => {
    if ((value == undefined || value.length == 0) || (value.length >= 14 && value.match('^([a-zA-Z]+[0-9]+|[0-9]+[a-zA-Z]+)[a-zA-z0-9]*$'))) {
      callback();
    } else {
      callback('英数字(各1文字以上含む)14文字以上で入力してください。');
    }
  }
}

