import {observable, runInAction} from 'mobx'
import {getCurrentTime} from "../../src/common/Api/time";


export class TimeStore {
  @observable
  public time:any;

  getCurrentTime = () => {
    getCurrentTime().then((res: any) => {
      runInAction(() => {
        this.time = res;
      });
    });
    // runInAction(() => {
    //   this.time = await getCurrentTime();
    // });
  }
}

export const timeStore = new TimeStore();


