import React from 'react';
import DocumentTitle from 'react-document-title';
import {Layout} from 'antd';
import enquire from 'enquire.js';
import {LoginUserStore} from "../../store/Login/LoginUserStore";
import {inject, observer} from "mobx-react";
import {globalStore} from "../../../src/store/GlobalStore";
import KixGbcGlobalHeader from "../../components/KixGbcGlobalHeader";
import commonStyles from '../../../src/styles/common.scss';
import KixGbcGlobalFooter from "../../components/KixGbcGlobalFooter";

import logo from '../../assets/img/logo.gif';
import {runInAction} from "mobx";
import {timeStore} from "../../store/TimeStore";
import {redirect} from "../../../src/common/Router/routerCommon";

const {Content} = Layout;

interface BasicLayoutProps {
  loginUserStore?: LoginUserStore
  notLogout?: boolean
  notFirstSetting?: boolean
}

@inject("loginUserStore")
@observer
class KixGbcMemberLayout extends React.Component<BasicLayoutProps, {}> {
  isMobile: boolean = false;
  loginUserStore = this.props.loginUserStore!;

  constructor(props: BasicLayoutProps, context: any) {
    super(props, context);

    enquire.register('screen and (max-width: 767.99px)', {
      match: () => this.isMobile = true,
      unmatch: () => this.isMobile = false,
    });

    // ユーザ情報取得
    runInAction(() => {
      this.loginUserStore.loginUserInfo = {};
    });
    timeStore.getCurrentTime();
    this.loginUserStore.getLoginUser().then(() => {
      if (!this.loginUserStore.loginUserInfo.id && !this.props.notLogout) {
        this.loginUserStore.logout();
        return;
      }

      if (!(this.loginUserStore.loginUserInfo.email || "").match("@") && !this.props.notLogout && !this.props.notFirstSetting) {
        redirect("/first_setting");
      }
    });

  }

  getPageTitle() {
    return "Global Business Club | 会員専用ページ";
  }

  render() {
    return (
      <DocumentTitle title={this.getPageTitle()}>
        <div>
          <KixGbcGlobalHeader
            logo={logo}
            currentUser={this.props.loginUserStore!.loginUserInfo}
            fetchingNotices={globalStore.fetchingNotices}
            notices={globalStore.notices}
            collapsed={globalStore.collapsed}
            isMobile={this.isMobile}
          />
          <Content style={{height: '100%', background: "#00125E"}} className={commonStyles.fCenter}>
            <div style={{minHeight: 'calc(100vh - 260px)', width: 900}}>
              {(!this.loginUserStore.loginUserInfo.id && !this.props.notLogout) || !timeStore.time
                ? ""
                : this.props.children
              }
            </div>
          </Content>
          <KixGbcGlobalFooter/>
        </div>
      </DocumentTitle>
    );
  }
}

export default KixGbcMemberLayout;
