import React from 'react';

export default class KixGbcGlobalFooter extends React.Component<any, any> {
  render() {
    const {
    } = this.props;

    return (
      <>
        <div id="footer" style={{margin: 0}}>
          {/*<div className="linkbox">*/}
            {/*<p className="link">*/}
              {/*<a href="https://www.kansai-airport.or.jp/kix-gbc/">ホーム</a>｜<a href="https://www.kansai-airport.or.jp/kix-gbc/service/">サービスのご案内</a>｜<a href="https://www.kansai-airport.or.jp/kix-gbc/pamph/">パンフレット・会員規約</a>｜<a*/}
              {/*href="https://www.kansai-airport.or.jp/kix-gbc/register/">ご入会方法</a></p>*/}
          {/*</div>*/}

          <div className="copybox">
            <p className="copy">
              <a href="/">関西国際空港公式サイト</a><br/>
              Copyright © Kansai Airports All Rights Reserved.</p>
          </div>
        </div>
      </>
    );
  }
}
