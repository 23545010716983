import {ReactElement} from "react";
import {RouteMap} from "../../types/router/route";
import {globalStore} from "../../store/GlobalStore";
import * as querystring from "querystring";

export function getRouteMap(routings: ReactElement<any>): RouteMap {
  let routerMap: RouteMap = {};
  let children = routings.props.children;
  for (let k in children) {
    routerMap[children[k].props.path] = children[k].props;
  }
  return routerMap;
}

export function getRouteParam(paramName: string) {
  return globalStore.routing.match.params[paramName];
}

export function getQueryParam(paramName: string): string | null {
  let query = querystring.parse(globalStore.routing.location.search.substr(1));
  return query[paramName] as string|| null;
}
