
export namespace RegexParts {
  // 半角数字
  export const digit = "0-9";

  // 半角大文字
  export const alphaUpper = "A-Z";

  // 半角小文字
  export const alphaLower = "a-z";

  // 半角英字
  export const alpha = "a-zA-Z";

  // 半角英数字
  export const alphaDigit = "0-9a-zA-Z";

  // 半角記号
  export const asciiSymbol = "!-/:-@[-`{-~";

  // ASCII文字
  export const ascii = "!-~";

  // 郵便番号（ハイフンなし）
  export const jpnPostalCode = "[\\d]{7}";

  // phone number
  export const phoneNum = "(\\+\\d{1,5}|0)(\\d{1,4}-\\d{1,4}-\\d{3,4}|\\d{5,12})";

  // email
  export const email = "[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*";

  // url
  export const url = "https?:\\/\\/[^\\s<>\"]+";

  // 全角大文字
  export const widAlphaUpper = "Ａ-Ｚ";

  // 全角小文字
  export const widAlphaLower = "ａ-ｚ";

  // 全角英字
  export const widAlpha = "Ａ-Ｚａ-ｚ";

  // 全角英数字
  export const widAlphaDigit = "０-９Ａ-Ｚａ-ｚ";

  // 全角カナ
  export const wideKana = "\u30A0-\u30FFー";

  // ひらがな
  export const wideHiragana = "\u3040-\u309fー";

  // 漢字
  export const kanji = "\u30e0-\u9fcf";

  // 全角空白 + 半角空白
  export const SpacesWithWide = " 　";
}

