import {BASE_PATH} from "../../swagger";
import moment from "moment";
import {HttpUtils} from "../../utils/Http/HttpUtils";
import json = HttpUtils.json;

export async function getCurrentTime() {
  return json(BASE_PATH + "/get_current_time", "POST", {}).then((res: any) => {
    const json = res.content;
    return moment(json.value);
  });
}
