import portableFetch from "portable-fetch";

export namespace HttpUtils {

  export async function json(url: string, method: string, bodyObj: any) {
    const body = JSON.stringify(bodyObj);
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };

    return portableFetch(url, {
      method,
      headers,
      body,
      credentials: "include"
    }).then((res: any) => {
      return new Promise((resolve) => {
        (async () => {
          const json = await res.json();
          res.content = json;
          resolve(res);
        })();
      });
    });
  }
}
