/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * gbc API
 * gbc API swagger documentation
 *
 * OpenAPI spec version: 1.0.0
 * Contact: example@example.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";
import portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

let base = "/api";
if (location.hostname == "localhost") {
  base = "http://210.129.48.245:43080/api";
}
export const BASE_PATH = base.replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * アップロード リクエストフォーム
 * @export
 * @interface AdminFileUploadRequest
 */
export class AdminFileUploadRequest {
    /**
     * file
     * @type {any}
     * @memberof AdminFileUploadRequest
     */
    file?: any;
}

/**
 * アップロード レスポンスフォーム
 * @export
 * @interface AdminFileUploadResponse
 */
export class AdminFileUploadResponse {
    /**
     *
     * @type {FileUploadForm}
     * @memberof AdminFileUploadResponse
     */
    file?: FileUploadForm;
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof AdminFileUploadResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof AdminFileUploadResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof AdminFileUploadResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 * ログイン情報取得レスポンス
 * @export
 * @interface AdminLoginIndexResponse
 */
export class AdminLoginIndexResponse {
    /**
     * ユーザID
     * @type {string}
     * @memberof AdminLoginIndexResponse
     */
    id?: string;
    /**
     * ユーザコード
     * @type {string}
     * @memberof AdminLoginIndexResponse
     */
    userCode?: string;
    /**
     * 名前
     * @type {string}
     * @memberof AdminLoginIndexResponse
     */
    name?: string;
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof AdminLoginIndexResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof AdminLoginIndexResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof AdminLoginIndexResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 * ログインリクエスト
 * @export
 * @interface AdminLoginStoreRequest
 */
export class AdminLoginStoreRequest {
    /**
     * ユーザID
     * @type {string}
     * @memberof AdminLoginStoreRequest
     */
    userCode: string;
    /**
     * パスワード
     * @type {string}
     * @memberof AdminLoginStoreRequest
     */
    password: string;
}

/**
 * ログイン実行レスポンス
 * @export
 * @interface AdminLoginStoreResponse
 */
export class AdminLoginStoreResponse {
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof AdminLoginStoreResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof AdminLoginStoreResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof AdminLoginStoreResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 *
 * @export
 * @interface AdminUsers
 */
export class AdminUsers {
    /**
     *
     * @type {number}
     * @memberof AdminUsers
     */
    id: number;
    /**
     * ユーザID
     * @type {string}
     * @memberof AdminUsers
     */
    user_code?: string;
    /**
     * ユーザ名
     * @type {string}
     * @memberof AdminUsers
     */
    name: string;
    /**
     * パスワード
     * @type {string}
     * @memberof AdminUsers
     */
    password: string;
    /**
     *
     * @type {string}
     * @memberof AdminUsers
     */
    deleted_at?: string;
    /**
     *
     * @type {string}
     * @memberof AdminUsers
     */
    created_at?: string;
    /**
     *
     * @type {string}
     * @memberof AdminUsers
     */
    updated_at?: string;
    /**
     * ログイン失敗数
     * @type {number}
     * @memberof AdminUsers
     */
    error_count?: number;
    /**
     * ログイン失敗日時
     * @type {string}
     * @memberof AdminUsers
     */
    error_dt?: string;
}

/**
 * 会員誌ライブラリ削除 リクエストフォーム
 * @export
 * @interface AdminUsersDeleteRequest
 */
export class AdminUsersDeleteRequest {
    /**
     * ID
     * @type {number}
     * @memberof AdminUsersDeleteRequest
     */
    id?: number;
}

/**
 * 会員誌ライブラリ削除 レスポンスフォーム
 * @export
 * @interface AdminUsersDeleteResponse
 */
export class AdminUsersDeleteResponse {
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof AdminUsersDeleteResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof AdminUsersDeleteResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof AdminUsersDeleteResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 * 会員誌ライブラリ一覧 リクエストフォーム
 * @export
 * @interface AdminUsersGetListRequest
 */
export class AdminUsersGetListRequest {
    /**
     * ページングデータ
     * @type {PaginationRequest}
     * @memberof AdminUsersGetListRequest
     */
    pagination?: PaginationRequest;
    /**
     * 検索条件
     * @type {AdminUsersSearchRequest}
     * @memberof AdminUsersGetListRequest
     */
    search?: AdminUsersSearchRequest;
}

/**
 * 会員誌ライブラリ一覧 レスポンスフォーム
 * @export
 * @interface AdminUsersGetListResponse
 */
export class AdminUsersGetListResponse {
    /**
     * データリスト
     * @type {Array&lt;AdminUsersGetListRowForm&gt;}
     * @memberof AdminUsersGetListResponse
     */
    list?: Array<AdminUsersGetListRowForm>;
    /**
     * 総合件数
     * @type {number}
     * @memberof AdminUsersGetListResponse
     */
    total?: number;
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof AdminUsersGetListResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof AdminUsersGetListResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof AdminUsersGetListResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 * 会員誌ライブラリ 行データ
 * @export
 * @interface AdminUsersGetListRowForm
 */
export class AdminUsersGetListRowForm {
    /**
     * 会員誌ライブラリ
     * @type {AdminUsers}
     * @memberof AdminUsersGetListRowForm
     */
    admin_users?: AdminUsers;
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof AdminUsersGetListRowForm
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof AdminUsersGetListRowForm
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof AdminUsersGetListRowForm
     */
    validationErrors?: { [key: string]: string; };
}

/**
 * 会員誌ライブラリ登録 リクエストフォーム
 * @export
 * @interface AdminUsersSaveRequest
 */
export class AdminUsersSaveRequest {
    /**
     * 会員誌ライブラリ
     * @type {AdminUsers}
     * @memberof AdminUsersSaveRequest
     */
    admin_user?: AdminUsers;
}

/**
 * 会員誌ライブラリ登録 レスポンスフォーム
 * @export
 * @interface AdminUsersSaveResponse
 */
export class AdminUsersSaveResponse {
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof AdminUsersSaveResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof AdminUsersSaveResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof AdminUsersSaveResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 *
 * @export
 * @interface AdminUsersSearchRequest
 */
export class AdminUsersSearchRequest {
    /**
     * ID
     * @type {number}
     * @memberof AdminUsersSearchRequest
     */
    id?: number;
}

/**
 * データ更新履歴データ
 * @export
 * @interface AuditingForm
 */
export class AuditingForm {
    /**
     * ID(一意キー)
     * @type {number}
     * @memberof AuditingForm
     */
    id?: number;
    /**
     * イベント
     * @type {string}
     * @memberof AuditingForm
     */
    event?: string;
    /**
     * 更新値
     * @type {string}
     * @memberof AuditingForm
     */
    oldValues?: string;
    /**
     * 更新値
     * @type {string}
     * @memberof AuditingForm
     */
    newValues?: string;
    /**
     * 更新したレコードのID(一意キー)
     * @type {number}
     * @memberof AuditingForm
     */
    auditableId?: number;
    /**
     * タグ
     * @type {string}
     * @memberof AuditingForm
     */
    tags?: string;
    /**
     *
     * @type {string}
     * @memberof AuditingForm
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof AuditingForm
     */
    updatedAt?: string;
}

/**
 * データ更新履歴 検索条件 行データ
 * @export
 * @interface AuditingsCondition
 */
export class AuditingsCondition {
    /**
     * 対象カラム
     * @type {string}
     * @memberof AuditingsCondition
     */
    column?: string;
    /**
     * 検索タイプ
     * @type {string}
     * @memberof AuditingsCondition
     */
    type?: string;
    /**
     * 値1
     * @type {string}
     * @memberof AuditingsCondition
     */
    value1?: string;
    /**
     * 値2
     * @type {string}
     * @memberof AuditingsCondition
     */
    value2?: string;
}

/**
 * データ更新履歴 リクエストフォーム
 * @export
 * @interface AuditingsGetListRequest
 */
export class AuditingsGetListRequest {
    /**
     * ページングデータ
     * @type {PaginationRequest}
     * @memberof AuditingsGetListRequest
     */
    pagination?: PaginationRequest;
    /**
     * 検索条件
     * @type {AuditingsSearchRequest}
     * @memberof AuditingsGetListRequest
     */
    search?: AuditingsSearchRequest;
}

/**
 * データ更新履歴 レスポンスフォーム
 * @export
 * @interface AuditingsGetListResponse
 */
export class AuditingsGetListResponse {
    /**
     * データリスト
     * @type {Array&lt;AuditingsGetListRowForm&gt;}
     * @memberof AuditingsGetListResponse
     */
    list?: Array<AuditingsGetListRowForm>;
    /**
     * 総合件数
     * @type {number}
     * @memberof AuditingsGetListResponse
     */
    total?: number;
    /**
     * データ更新履歴 列情報
     * @type {Array&lt;string&gt;}
     * @memberof AuditingsGetListResponse
     */
    columns?: Array<string>;
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof AuditingsGetListResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof AuditingsGetListResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof AuditingsGetListResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 * データ更新履歴一覧 行データ
 * @export
 * @interface AuditingsGetListRowForm
 */
export class AuditingsGetListRowForm {
    /**
     * データ更新履歴 行データ
     * @type {AuditingForm}
     * @memberof AuditingsGetListRowForm
     */
    audits?: AuditingForm;
}

/**
 * データ更新履歴検索
 * @export
 * @interface AuditingsSearchRequest
 */
export class AuditingsSearchRequest {
    /**
     * データ更新履歴ID
     * @type {number}
     * @memberof AuditingsSearchRequest
     */
    id?: number;
    /**
     * 対象テーブル
     * @type {string}
     * @memberof AuditingsSearchRequest
     */
    table?: string;
    /**
     * 条件リスト
     * @type {Array&lt;AuditingsCondition&gt;}
     * @memberof AuditingsSearchRequest
     */
    conditions?: Array<AuditingsCondition>;
}

/**
 *
 * @export
 * @interface Audits
 */
export class Audits {
    /**
     *
     * @type {number}
     * @memberof Audits
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof Audits
     */
    user_type?: string;
    /**
     *
     * @type {number}
     * @memberof Audits
     */
    user_id?: number;
    /**
     *
     * @type {string}
     * @memberof Audits
     */
    event: string;
    /**
     *
     * @type {number}
     * @memberof Audits
     */
    auditable_id?: number;
    /**
     *
     * @type {string}
     * @memberof Audits
     */
    auditable_type?: string;
    /**
     *
     * @type {string}
     * @memberof Audits
     */
    old_values?: string;
    /**
     *
     * @type {string}
     * @memberof Audits
     */
    new_values?: string;
    /**
     *
     * @type {string}
     * @memberof Audits
     */
    url: string;
    /**
     *
     * @type {string}
     * @memberof Audits
     */
    ip_address?: string;
    /**
     *
     * @type {string}
     * @memberof Audits
     */
    user_agent?: string;
    /**
     *
     * @type {string}
     * @memberof Audits
     */
    tags: string;
    /**
     *
     * @type {string}
     * @memberof Audits
     */
    created_at?: string;
    /**
     *
     * @type {string}
     * @memberof Audits
     */
    updated_at?: string;
}

/**
 *
 * @export
 * @interface Banners
 */
export class Banners {
    /**
     *
     * @type {number}
     * @memberof Banners
     */
    id: number;
    /**
     * 1: 公開、2: 非公開
     * @type {number}
     * @memberof Banners
     */
    status: number;
    /**
     * タイトル
     * @type {string}
     * @memberof Banners
     */
    title: string;
    /**
     * リンク
     * @type {string}
     * @memberof Banners
     */
    link: string;
    /**
     * URL
     * @type {string}
     * @memberof Banners
     */
    image_url?: string;
    /**
     * ファイル名
     * @type {string}
     * @memberof Banners
     */
    image_filename?: string;
    /**
     *
     * @type {string}
     * @memberof Banners
     */
    deleted_at?: string;
    /**
     *
     * @type {string}
     * @memberof Banners
     */
    created_at?: string;
    /**
     *
     * @type {string}
     * @memberof Banners
     */
    updated_at?: string;
}

/**
 * 会員誌ライブラリ削除 リクエストフォーム
 * @export
 * @interface BannersDeleteRequest
 */
export class BannersDeleteRequest {
    /**
     * ID
     * @type {number}
     * @memberof BannersDeleteRequest
     */
    id?: number;
}

/**
 * 会員誌ライブラリ削除 レスポンスフォーム
 * @export
 * @interface BannersDeleteResponse
 */
export class BannersDeleteResponse {
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof BannersDeleteResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof BannersDeleteResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof BannersDeleteResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 * 会員誌ライブラリ一覧 リクエストフォーム
 * @export
 * @interface BannersGetListRequest
 */
export class BannersGetListRequest {
    /**
     * ページングデータ
     * @type {PaginationRequest}
     * @memberof BannersGetListRequest
     */
    pagination?: PaginationRequest;
    /**
     * 検索条件
     * @type {BannersSearchRequest}
     * @memberof BannersGetListRequest
     */
    search?: BannersSearchRequest;
}

/**
 * 会員誌ライブラリ一覧 レスポンスフォーム
 * @export
 * @interface BannersGetListResponse
 */
export class BannersGetListResponse {
    /**
     * データリスト
     * @type {Array&lt;BannersGetListRowForm&gt;}
     * @memberof BannersGetListResponse
     */
    list?: Array<BannersGetListRowForm>;
    /**
     * 総合件数
     * @type {number}
     * @memberof BannersGetListResponse
     */
    total?: number;
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof BannersGetListResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof BannersGetListResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof BannersGetListResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 * 会員誌ライブラリ 行データ
 * @export
 * @interface BannersGetListRowForm
 */
export class BannersGetListRowForm {
    /**
     * 会員誌ライブラリ
     * @type {Banners}
     * @memberof BannersGetListRowForm
     */
    banners?: Banners;
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof BannersGetListRowForm
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof BannersGetListRowForm
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof BannersGetListRowForm
     */
    validationErrors?: { [key: string]: string; };
}

/**
 * 会員誌ライブラリ登録 リクエストフォーム
 * @export
 * @interface BannersSaveRequest
 */
export class BannersSaveRequest {
    /**
     * 会員誌ライブラリ
     * @type {Banners}
     * @memberof BannersSaveRequest
     */
    banner?: Banners;
}

/**
 * 会員誌ライブラリ登録 レスポンスフォーム
 * @export
 * @interface BannersSaveResponse
 */
export class BannersSaveResponse {
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof BannersSaveResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof BannersSaveResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof BannersSaveResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 *
 * @export
 * @interface BannersSearchRequest
 */
export class BannersSearchRequest {
    /**
     * ID
     * @type {number}
     * @memberof BannersSearchRequest
     */
    id?: number;
}

/**
 * 基本APIリクエスト
 * @export
 * @interface BaseApiRequest
 */
export class BaseApiRequest {
    /**
     *
     * @type {Array&lt;SortRequest&gt;}
     * @memberof BaseApiRequest
     */
    sort?: Array<SortRequest>;
    /**
     *
     * @type {number}
     * @memberof BaseApiRequest
     */
    isUserMode?: number;
}

/**
 * 基本レスポンス
 * @export
 * @interface BaseApiResponse
 */
export class BaseApiResponse {
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof BaseApiResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof BaseApiResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof BaseApiResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 *
 * @export
 * @interface Companies
 */
export class Companies {
    /**
     *
     * @type {number}
     * @memberof Companies
     */
    id: number;
    /**
     * 会社名
     * @type {string}
     * @memberof Companies
     */
    name: string;
    /**
     * ステータス(1:利用中 2:退会 3:更新手続き中 4:入金待ち)
     * @type {number}
     * @memberof Companies
     */
    status: number;
    /**
     * コース(1:Light、2:Standard)
     * @type {number}
     * @memberof Companies
     */
    course: number;
    /**
     * 有効期限
     * @type {string}
     * @memberof Companies
     */
    expiration_dt?: string;
    /**
     * ファストレーン合計使用回数
     * @type {number}
     * @memberof Companies
     */
    fastlane_used_count?: number;
    /**
     * ファストレーン利用回数制限
     * @type {number}
     * @memberof Companies
     */
    fastlane_max_usage_count?: number;
    /**
     * ラウンジ合計使用回数
     * @type {number}
     * @memberof Companies
     */
    lounge_used_count?: number;
    /**
     * ラウンジ利用回数制限
     * @type {number}
     * @memberof Companies
     */
    lounge_max_usage_count?: number;
    /**
     * 担当者
     * @type {string}
     * @memberof Companies
     */
    pic: string;
    /**
     * 部署
     * @type {string}
     * @memberof Companies
     */
    department: string;
    /**
     * 役職
     * @type {string}
     * @memberof Companies
     */
    official_position?: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof Companies
     */
    tel: string;
    /**
     * メールアドレス1
     * @type {string}
     * @memberof Companies
     */
    email1: string;
    /**
     * メールアドレス2
     * @type {string}
     * @memberof Companies
     */
    email2: string;
    /**
     * メールアドレス3
     * @type {string}
     * @memberof Companies
     */
    email3: string;
    /**
     * メールアドレス4
     * @type {string}
     * @memberof Companies
     */
    email4: string;
    /**
     * メールアドレス5
     * @type {string}
     * @memberof Companies
     */
    email5: string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof Companies
     */
    postal_code?: string;
    /**
     * 住所1
     * @type {string}
     * @memberof Companies
     */
    address1: string;
    /**
     * 住所2
     * @type {string}
     * @memberof Companies
     */
    address2: string;
    /**
     * 備考
     * @type {string}
     * @memberof Companies
     */
    remarks: string;
    /**
     *
     * @type {string}
     * @memberof Companies
     */
    deleted_at?: string;
    /**
     *
     * @type {string}
     * @memberof Companies
     */
    created_at?: string;
    /**
     *
     * @type {string}
     * @memberof Companies
     */
    updated_at?: string;
    /**
     * 前回有効期限
     * @type {string}
     * @memberof Companies
     */
    pre_expiration_dt?: string;
}

/**
 * CSVダウンロード リクエストフォーム
 * @export
 * @interface CompaniesCsvDownloadRequest
 */
export class CompaniesCsvDownloadRequest {
    /**
     *
     * @type {CompaniesGetListRequest}
     * @memberof CompaniesCsvDownloadRequest
     */
    req?: CompaniesGetListRequest;
}

/**
 * CSVダウンロード レスポンスフォーム
 * @export
 * @interface CompaniesCsvDownloadResponse
 */
export class CompaniesCsvDownloadResponse {
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof CompaniesCsvDownloadResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof CompaniesCsvDownloadResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof CompaniesCsvDownloadResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 * 企業削除 リクエストフォーム
 * @export
 * @interface CompaniesDeleteRequest
 */
export class CompaniesDeleteRequest {
    /**
     * ID
     * @type {number}
     * @memberof CompaniesDeleteRequest
     */
    id?: number;
}

/**
 * 企業削除 レスポンスフォーム
 * @export
 * @interface CompaniesDeleteResponse
 */
export class CompaniesDeleteResponse {
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof CompaniesDeleteResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof CompaniesDeleteResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof CompaniesDeleteResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 * 企業一覧 リクエストフォーム
 * @export
 * @interface CompaniesGetListRequest
 */
export class CompaniesGetListRequest {
    /**
     * ページングデータ
     * @type {PaginationRequest}
     * @memberof CompaniesGetListRequest
     */
    pagination?: PaginationRequest;
    /**
     * 検索条件
     * @type {CompaniesSearchRequest}
     * @memberof CompaniesGetListRequest
     */
    search?: CompaniesSearchRequest;
    /**
     *
     * @type {Array&lt;SortRequest&gt;}
     * @memberof CompaniesGetListRequest
     */
    sort?: Array<SortRequest>;
    /**
     *
     * @type {number}
     * @memberof CompaniesGetListRequest
     */
    isUserMode?: number;
}

/**
 * 企業一覧 レスポンスフォーム
 * @export
 * @interface CompaniesGetListResponse
 */
export class CompaniesGetListResponse {
    /**
     * データリスト
     * @type {Array&lt;CompaniesGetListRowForm&gt;}
     * @memberof CompaniesGetListResponse
     */
    list?: Array<CompaniesGetListRowForm>;
    /**
     * 総合件数
     * @type {number}
     * @memberof CompaniesGetListResponse
     */
    total?: number;
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof CompaniesGetListResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof CompaniesGetListResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof CompaniesGetListResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 * 行データ
 * @export
 * @interface CompaniesGetListRowForm
 */
export class CompaniesGetListRowForm {
    /**
     *
     * @type {Companies}
     * @memberof CompaniesGetListRowForm
     */
    companies?: Companies;
    /**
     *
     * @type {CompanySchedules}
     * @memberof CompaniesGetListRowForm
     */
    company_schedules?: CompanySchedules;
}

/**
 * 企業更新 リクエストフォーム
 * @export
 * @interface CompaniesSaveRequest
 */
export class CompaniesSaveRequest {
    /**
     *
     * @type {Companies}
     * @memberof CompaniesSaveRequest
     */
    company?: Companies;
    /**
     *
     * @type {CompanySchedules}
     * @memberof CompaniesSaveRequest
     */
    company_schedule?: CompanySchedules;
}

/**
 * 企業更新 レスポンスフォーム
 * @export
 * @interface CompaniesSaveResponse
 */
export class CompaniesSaveResponse {
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof CompaniesSaveResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof CompaniesSaveResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof CompaniesSaveResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 *
 * @export
 * @interface CompaniesSearchRequest
 */
export class CompaniesSearchRequest {
    /**
     * ID(一意キー)
     * @type {number}
     * @memberof CompaniesSearchRequest
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof CompaniesSearchRequest
     */
    name?: string;
    /**
     *
     * @type {number}
     * @memberof CompaniesSearchRequest
     */
    not_available?: number;
}

/**
 *
 * @export
 * @interface CompanySchedules
 */
export class CompanySchedules {
    /**
     *
     * @type {number}
     * @memberof CompanySchedules
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof CompanySchedules
     */
    company_id?: number;
    /**
     * 変更スケジュール月
     * @type {string}
     * @memberof CompanySchedules
     */
    schedule_date?: string;
    /**
     * コース(1:Light、2:Standard)
     * @type {number}
     * @memberof CompanySchedules
     */
    course: number;
    /**
     * ファストレーン利用回数制限
     * @type {number}
     * @memberof CompanySchedules
     */
    fastlane_max_usage_count?: number;
    /**
     * ラウンジ利用回数制限
     * @type {number}
     * @memberof CompanySchedules
     */
    lounge_max_usage_count?: number;
    /**
     * 適用済みフラグ
     * @type {number}
     * @memberof CompanySchedules
     */
    is_changed?: number;
    /**
     *
     * @type {string}
     * @memberof CompanySchedules
     */
    deleted_at?: string;
    /**
     *
     * @type {string}
     * @memberof CompanySchedules
     */
    created_at?: string;
    /**
     *
     * @type {string}
     * @memberof CompanySchedules
     */
    updated_at?: string;
}

/**
 *
 * @export
 * @interface CouponTabs
 */
export class CouponTabs {
    /**
     *
     * @type {number}
     * @memberof CouponTabs
     */
    id: number;
    /**
     * 1: 公開、2: 非公開
     * @type {number}
     * @memberof CouponTabs
     */
    status: number;
    /**
     * タイトル
     * @type {string}
     * @memberof CouponTabs
     */
    title: string;
    /**
     * 掲載順
     * @type {number}
     * @memberof CouponTabs
     */
    order_num?: number;
    /**
     *
     * @type {string}
     * @memberof CouponTabs
     */
    deleted_at?: string;
    /**
     *
     * @type {string}
     * @memberof CouponTabs
     */
    created_at?: string;
    /**
     *
     * @type {string}
     * @memberof CouponTabs
     */
    updated_at?: string;
}

/**
 * 会員誌ライブラリ削除 リクエストフォーム
 * @export
 * @interface CouponTabsDeleteRequest
 */
export class CouponTabsDeleteRequest {
    /**
     * ID
     * @type {number}
     * @memberof CouponTabsDeleteRequest
     */
    id?: number;
}

/**
 * 会員誌ライブラリ削除 レスポンスフォーム
 * @export
 * @interface CouponTabsDeleteResponse
 */
export class CouponTabsDeleteResponse {
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof CouponTabsDeleteResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof CouponTabsDeleteResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof CouponTabsDeleteResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 * 会員誌ライブラリ一覧 リクエストフォーム
 * @export
 * @interface CouponTabsGetListRequest
 */
export class CouponTabsGetListRequest {
    /**
     * ページングデータ
     * @type {PaginationRequest}
     * @memberof CouponTabsGetListRequest
     */
    pagination?: PaginationRequest;
    /**
     * 検索条件
     * @type {CouponTabsSearchRequest}
     * @memberof CouponTabsGetListRequest
     */
    search?: CouponTabsSearchRequest;
}

/**
 * 会員誌ライブラリ一覧 レスポンスフォーム
 * @export
 * @interface CouponTabsGetListResponse
 */
export class CouponTabsGetListResponse {
    /**
     * データリスト
     * @type {Array&lt;CouponTabsGetListRowForm&gt;}
     * @memberof CouponTabsGetListResponse
     */
    list?: Array<CouponTabsGetListRowForm>;
    /**
     * 総合件数
     * @type {number}
     * @memberof CouponTabsGetListResponse
     */
    total?: number;
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof CouponTabsGetListResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof CouponTabsGetListResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof CouponTabsGetListResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 * 会員誌ライブラリ 行データ
 * @export
 * @interface CouponTabsGetListRowForm
 */
export class CouponTabsGetListRowForm {
    /**
     * 会員誌ライブラリ
     * @type {CouponTabs}
     * @memberof CouponTabsGetListRowForm
     */
    coupon_tabs?: CouponTabs;
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof CouponTabsGetListRowForm
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof CouponTabsGetListRowForm
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof CouponTabsGetListRowForm
     */
    validationErrors?: { [key: string]: string; };
}

/**
 * 会員誌ライブラリ登録 リクエストフォーム
 * @export
 * @interface CouponTabsSaveRequest
 */
export class CouponTabsSaveRequest {
    /**
     * 会員誌ライブラリ
     * @type {CouponTabs}
     * @memberof CouponTabsSaveRequest
     */
    couponTab?: CouponTabs;
}

/**
 * 会員誌ライブラリ登録 レスポンスフォーム
 * @export
 * @interface CouponTabsSaveResponse
 */
export class CouponTabsSaveResponse {
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof CouponTabsSaveResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof CouponTabsSaveResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof CouponTabsSaveResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 *
 * @export
 * @interface CouponTabsSearchRequest
 */
export class CouponTabsSearchRequest {
    /**
     * ID
     * @type {number}
     * @memberof CouponTabsSearchRequest
     */
    id?: number;
}

/**
 *
 * @export
 * @interface Coupons
 */
export class Coupons {
    /**
     *
     * @type {number}
     * @memberof Coupons
     */
    id: number;
    /**
     * 1: 公開、2: 非公開
     * @type {number}
     * @memberof Coupons
     */
    status: number;
    /**
     * タイトル
     * @type {string}
     * @memberof Coupons
     */
    title: string;
    /**
     * サムネイルのURL
     * @type {string}
     * @memberof Coupons
     */
    thumbnail_url?: string;
    /**
     * サムネイルのファイル名
     * @type {string}
     * @memberof Coupons
     */
    thumbnail_filename?: string;
    /**
     * URL
     * @type {string}
     * @memberof Coupons
     */
    image_url?: string;
    /**
     * ファイル名
     * @type {string}
     * @memberof Coupons
     */
    image_filename?: string;
    /**
     * 有効期限(開始)
     * @type {string}
     * @memberof Coupons
     */
    expiration_start_dt?: string;
    /**
     * 有効期限(終了)
     * @type {string}
     * @memberof Coupons
     */
    expiration_end_dt?: string;
    /**
     * 本文
     * @type {string}
     * @memberof Coupons
     */
    body: string;
    /**
     * 外部キー: coupon_tabs.id
     * @type {number}
     * @memberof Coupons
     */
    coupon_tab_id?: number;
    /**
     * 掲載順
     * @type {number}
     * @memberof Coupons
     */
    order_num?: number;
    /**
     *
     * @type {string}
     * @memberof Coupons
     */
    deleted_at?: string;
    /**
     *
     * @type {string}
     * @memberof Coupons
     */
    created_at?: string;
    /**
     *
     * @type {string}
     * @memberof Coupons
     */
    updated_at?: string;
}

/**
 * 会員誌ライブラリ削除 リクエストフォーム
 * @export
 * @interface CouponsDeleteRequest
 */
export class CouponsDeleteRequest {
    /**
     * ID
     * @type {number}
     * @memberof CouponsDeleteRequest
     */
    id?: number;
}

/**
 * 会員誌ライブラリ削除 レスポンスフォーム
 * @export
 * @interface CouponsDeleteResponse
 */
export class CouponsDeleteResponse {
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof CouponsDeleteResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof CouponsDeleteResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof CouponsDeleteResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 * 会員誌ライブラリ一覧 リクエストフォーム
 * @export
 * @interface CouponsGetListRequest
 */
export class CouponsGetListRequest {
    /**
     * ページングデータ
     * @type {PaginationRequest}
     * @memberof CouponsGetListRequest
     */
    pagination?: PaginationRequest;
    /**
     * 検索条件
     * @type {CouponsSearchRequest}
     * @memberof CouponsGetListRequest
     */
    search?: CouponsSearchRequest;
}

/**
 * 会員誌ライブラリ一覧 レスポンスフォーム
 * @export
 * @interface CouponsGetListResponse
 */
export class CouponsGetListResponse {
    /**
     * データリスト
     * @type {Array&lt;CouponsGetListRowForm&gt;}
     * @memberof CouponsGetListResponse
     */
    list?: Array<CouponsGetListRowForm>;
    /**
     * 総合件数
     * @type {number}
     * @memberof CouponsGetListResponse
     */
    total?: number;
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof CouponsGetListResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof CouponsGetListResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof CouponsGetListResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 * 会員誌ライブラリ 行データ
 * @export
 * @interface CouponsGetListRowForm
 */
export class CouponsGetListRowForm {
    /**
     * 会員誌ライブラリ
     * @type {Coupons}
     * @memberof CouponsGetListRowForm
     */
    coupons?: Coupons;
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof CouponsGetListRowForm
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof CouponsGetListRowForm
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof CouponsGetListRowForm
     */
    validationErrors?: { [key: string]: string; };
}

/**
 * 会員誌ライブラリ登録 リクエストフォーム
 * @export
 * @interface CouponsSaveRequest
 */
export class CouponsSaveRequest {
    /**
     * 会員誌ライブラリ
     * @type {Coupons}
     * @memberof CouponsSaveRequest
     */
    coupon?: Coupons;
}

/**
 * 会員誌ライブラリ登録 レスポンスフォーム
 * @export
 * @interface CouponsSaveResponse
 */
export class CouponsSaveResponse {
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof CouponsSaveResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof CouponsSaveResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof CouponsSaveResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 *
 * @export
 * @interface CouponsSearchRequest
 */
export class CouponsSearchRequest {
    /**
     * ID
     * @type {number}
     * @memberof CouponsSearchRequest
     */
    id?: number;
    /**
     * ID
     * @type {number}
     * @memberof CouponsSearchRequest
     */
    user_mode?: number;
}

/**
 * エラーレスポンス
 * @export
 * @interface ErrorResponse
 */
export class ErrorResponse {
    /**
     * エラーコード
     * @type {string}
     * @memberof ErrorResponse
     */
    code: string;
    /**
     * エラーメッセージ
     * @type {string}
     * @memberof ErrorResponse
     */
    message: string;
}

/**
 * エラーレスポンスリスト
 * @export
 * @interface ErrorsResponse
 */
export class ErrorsResponse {
    /**
     * エラーリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof ErrorsResponse
     */
    errors?: Array<ErrorResponse>;
}

/**
 *
 * @export
 * @interface FastlaneHistories
 */
export class FastlaneHistories {
    /**
     *
     * @type {number}
     * @memberof FastlaneHistories
     */
    id: number;
    /**
     * 外部キー: users.id
     * @type {number}
     * @memberof FastlaneHistories
     */
    user_id?: number;
    /**
     * 1:PC 2:SP
     * @type {number}
     * @memberof FastlaneHistories
     */
    device: number;
    /**
     * 1:FastLane　2:ラウンジ利用
     * @type {number}
     * @memberof FastlaneHistories
     */
    is_lane?: number;
    /**
     * 端末時刻
     * @type {string}
     * @memberof FastlaneHistories
     */
    date: string;
    /**
     * 搭乗日
     * @type {string}
     * @memberof FastlaneHistories
     */
    flight_date?: string;
    /**
     * 行き先方面
     * @type {string}
     * @memberof FastlaneHistories
     */
    dest: string;
    /**
     * 便名
     * @type {string}
     * @memberof FastlaneHistories
     */
    flightName: string;
    /**
     * クラス番号 1:エコノミー 2:プレミアムエコノミー 3:ビジネス 4:ファースト
     * @type {number}
     * @memberof FastlaneHistories
     */
    flightClass: number;
    /**
     *
     * @type {string}
     * @memberof FastlaneHistories
     */
    deleted_at?: string;
    /**
     *
     * @type {string}
     * @memberof FastlaneHistories
     */
    created_at?: string;
    /**
     *
     * @type {string}
     * @memberof FastlaneHistories
     */
    updated_at?: string;
    /**
     * トークン
     * @type {string}
     * @memberof FastlaneHistories
     */
    token: string;
    /**
     * キャンセルフラグ
     * @type {number}
     * @memberof FastlaneHistories
     */
    is_cancel?: number;
}

/**
 * ファイルデータ
 * @export
 * @interface FileUploadForm
 */
export class FileUploadForm {
    /**
     *
     * @type {string}
     * @memberof FileUploadForm
     */
    uid?: string;
    /**
     *
     * @type {string}
     * @memberof FileUploadForm
     */
    size?: string;
    /**
     *
     * @type {string}
     * @memberof FileUploadForm
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof FileUploadForm
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof FileUploadForm
     */
    url?: string;
}

/**
 *
 * @export
 * @interface FirstAccountSetting
 */
export class FirstAccountSetting {
    /**
     *
     * @type {number}
     * @memberof FirstAccountSetting
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof FirstAccountSetting
     */
    user_id?: number;
    /**
     * メールアドレス
     * @type {string}
     * @memberof FirstAccountSetting
     */
    email: string;
    /**
     * パスワード
     * @type {string}
     * @memberof FirstAccountSetting
     */
    password: string;
    /**
     * パスワードリセットトークん
     * @type {string}
     * @memberof FirstAccountSetting
     */
    reset_token?: string;
    /**
     * パスワードリセット有効期限
     * @type {string}
     * @memberof FirstAccountSetting
     */
    reset_limit?: string;
    /**
     *
     * @type {string}
     * @memberof FirstAccountSetting
     */
    created_at?: string;
    /**
     *
     * @type {string}
     * @memberof FirstAccountSetting
     */
    updated_at?: string;
}

/**
 * CSVダウンロード リクエストフォーム
 * @export
 * @interface HistoriesCsvDownloadRequest
 */
export class HistoriesCsvDownloadRequest {
}

/**
 * CSVダウンロード レスポンスフォーム
 * @export
 * @interface HistoriesCsvDownloadResponse
 */
export class HistoriesCsvDownloadResponse {
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof HistoriesCsvDownloadResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof HistoriesCsvDownloadResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof HistoriesCsvDownloadResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 * 利用履歴 リクエストフォーム
 * @export
 * @interface HistoriesGetListRequest
 */
export class HistoriesGetListRequest {
    /**
     * ページングデータ
     * @type {PaginationRequest}
     * @memberof HistoriesGetListRequest
     */
    pagination?: PaginationRequest;
    /**
     * 検索条件
     * @type {HistoriesSearchRequest}
     * @memberof HistoriesGetListRequest
     */
    search?: HistoriesSearchRequest;
}

/**
 * 利用履歴 レスポンスフォーム
 * @export
 * @interface HistoriesGetListResponse
 */
export class HistoriesGetListResponse {
    /**
     * データリスト
     * @type {Array&lt;HistoriesGetListRowForm&gt;}
     * @memberof HistoriesGetListResponse
     */
    list?: Array<HistoriesGetListRowForm>;
    /**
     * 総合件数
     * @type {number}
     * @memberof HistoriesGetListResponse
     */
    total?: number;
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof HistoriesGetListResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof HistoriesGetListResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof HistoriesGetListResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 * 利用履歴 行データ
 * @export
 * @interface HistoriesGetListRowForm
 */
export class HistoriesGetListRowForm {
    /**
     * 利用履歴
     * @type {FastlaneHistories}
     * @memberof HistoriesGetListRowForm
     */
    fastlane_histories?: FastlaneHistories;
    /**
     * ユーザ
     * @type {Users}
     * @memberof HistoriesGetListRowForm
     */
    users?: Users;
    /**
     *
     * @type {Companies}
     * @memberof HistoriesGetListRowForm
     */
    companies?: Companies;
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof HistoriesGetListRowForm
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof HistoriesGetListRowForm
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof HistoriesGetListRowForm
     */
    validationErrors?: { [key: string]: string; };
}

/**
 *
 * @export
 * @interface HistoriesSearchRequest
 */
export class HistoriesSearchRequest {
    /**
     * ID
     * @type {number}
     * @memberof HistoriesSearchRequest
     */
    id?: number;
    /**
     *
     * @type {number}
     * @memberof HistoriesSearchRequest
     */
    company_id?: number;
    /**
     *
     * @type {string}
     * @memberof HistoriesSearchRequest
     */
    user_id?: string;
    /**
     *
     * @type {string}
     * @memberof HistoriesSearchRequest
     */
    company_name?: string;
    /**
     *
     * @type {string}
     * @memberof HistoriesSearchRequest
     */
    is_lane?: string;
    /**
     *
     * @type {string}
     * @memberof HistoriesSearchRequest
     */
    dateStart?: string;
    /**
     *
     * @type {string}
     * @memberof HistoriesSearchRequest
     */
    dateEnd?: string;
    /**
     *
     * @type {string}
     * @memberof HistoriesSearchRequest
     */
    flightDateStart?: string;
    /**
     *
     * @type {string}
     * @memberof HistoriesSearchRequest
     */
    flightDateEnd?: string;
}

/**
 * CSVダウンロード リクエストフォーム
 * @export
 * @interface InformationCsvDownloadRequest
 */
export class InformationCsvDownloadRequest {
}

/**
 * CSVダウンロード レスポンスフォーム
 * @export
 * @interface InformationCsvDownloadResponse
 */
export class InformationCsvDownloadResponse {
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof InformationCsvDownloadResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof InformationCsvDownloadResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof InformationCsvDownloadResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 * お知らせ削除 リクエストフォーム
 * @export
 * @interface InformationDeleteRequest
 */
export class InformationDeleteRequest {
    /**
     * ID
     * @type {number}
     * @memberof InformationDeleteRequest
     */
    id?: number;
}

/**
 * お知らせ削除 レスポンスフォーム
 * @export
 * @interface InformationDeleteResponse
 */
export class InformationDeleteResponse {
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof InformationDeleteResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof InformationDeleteResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof InformationDeleteResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 * お知らせ一覧 リクエストフォーム
 * @export
 * @interface InformationGetListRequest
 */
export class InformationGetListRequest {
    /**
     * ページングデータ
     * @type {PaginationRequest}
     * @memberof InformationGetListRequest
     */
    pagination?: PaginationRequest;
    /**
     * 検索条件
     * @type {InformationSearchRequest}
     * @memberof InformationGetListRequest
     */
    search?: InformationSearchRequest;
    /**
     *
     * @type {Array&lt;SortRequest&gt;}
     * @memberof InformationGetListRequest
     */
    sort?: Array<SortRequest>;
    /**
     *
     * @type {number}
     * @memberof InformationGetListRequest
     */
    isUserMode?: number;
}

/**
 * お知らせ一覧 レスポンスフォーム
 * @export
 * @interface InformationGetListResponse
 */
export class InformationGetListResponse {
    /**
     * データリスト
     * @type {Array&lt;InformationGetListRowForm&gt;}
     * @memberof InformationGetListResponse
     */
    list?: Array<InformationGetListRowForm>;
    /**
     * 総合件数
     * @type {number}
     * @memberof InformationGetListResponse
     */
    total?: number;
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof InformationGetListResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof InformationGetListResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof InformationGetListResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 *  行データ
 * @export
 * @interface InformationGetListRowForm
 */
export class InformationGetListRowForm {
    /**
     *
     * @type {Informations}
     * @memberof InformationGetListRowForm
     */
    informations?: Informations;
    /**
     *
     * @type {Array&lt;InformationTargetsRow&gt;}
     * @memberof InformationGetListRowForm
     */
    targets?: Array<InformationTargetsRow>;
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof InformationGetListRowForm
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof InformationGetListRowForm
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof InformationGetListRowForm
     */
    validationErrors?: { [key: string]: string; };
}

/**
 *
 * @export
 * @interface InformationRead
 */
export class InformationRead {
    /**
     *
     * @type {number}
     * @memberof InformationRead
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof InformationRead
     */
    information_id?: number;
    /**
     *
     * @type {number}
     * @memberof InformationRead
     */
    user_id?: number;
    /**
     *
     * @type {string}
     * @memberof InformationRead
     */
    created_at?: string;
    /**
     *
     * @type {string}
     * @memberof InformationRead
     */
    updated_at?: string;
}

/**
 * お知らせ更新 リクエストフォーム
 * @export
 * @interface InformationSaveRequest
 */
export class InformationSaveRequest {
    /**
     *
     * @type {Informations}
     * @memberof InformationSaveRequest
     */
    information?: Informations;
    /**
     *
     * @type {Array&lt;InformationTargets&gt;}
     * @memberof InformationSaveRequest
     */
    information_targets?: Array<InformationTargets>;
}

/**
 * お知らせ更新 レスポンスフォーム
 * @export
 * @interface InformationSaveResponse
 */
export class InformationSaveResponse {
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof InformationSaveResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof InformationSaveResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof InformationSaveResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 *
 * @export
 * @interface InformationSearchRequest
 */
export class InformationSearchRequest {
    /**
     * ID
     * @type {number}
     * @memberof InformationSearchRequest
     */
    id?: number;
    /**
     *
     * @type {number}
     * @memberof InformationSearchRequest
     */
    company_id?: number;
    /**
     *
     * @type {number}
     * @memberof InformationSearchRequest
     */
    status?: number;
}

/**
 *
 * @export
 * @interface InformationTargets
 */
export class InformationTargets {
    /**
     *
     * @type {number}
     * @memberof InformationTargets
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof InformationTargets
     */
    information_id?: number;
    /**
     *
     * @type {number}
     * @memberof InformationTargets
     */
    company_id?: number;
    /**
     *
     * @type {string}
     * @memberof InformationTargets
     */
    deleted_at?: string;
    /**
     *
     * @type {string}
     * @memberof InformationTargets
     */
    created_at?: string;
    /**
     *
     * @type {string}
     * @memberof InformationTargets
     */
    updated_at?: string;
}

/**
 *  行データ
 * @export
 * @interface InformationTargetsRow
 */
export class InformationTargetsRow {
    /**
     *
     * @type {InformationTargets}
     * @memberof InformationTargetsRow
     */
    information_targets?: InformationTargets;
    /**
     *
     * @type {Companies}
     * @memberof InformationTargetsRow
     */
    companies?: Companies;
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof InformationTargetsRow
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof InformationTargetsRow
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof InformationTargetsRow
     */
    validationErrors?: { [key: string]: string; };
}

/**
 *
 * @export
 * @interface Informations
 */
export class Informations {
    /**
     *
     * @type {number}
     * @memberof Informations
     */
    id: number;
    /**
     * 1: 公開、2: 非公開
     * @type {number}
     * @memberof Informations
     */
    status: number;
    /**
     * 公開日
     * @type {string}
     * @memberof Informations
     */
    publish_dt?: string;
    /**
     * タイトル
     * @type {string}
     * @memberof Informations
     */
    title: string;
    /**
     * 本文
     * @type {string}
     * @memberof Informations
     */
    body: string;
    /**
     * 1: 全会員、2: 企業ごと
     * @type {number}
     * @memberof Informations
     */
    target: number;
    /**
     * プッシュ通知を送るか
     * @type {number}
     * @memberof Informations
     */
    is_push?: number;
    /**
     *
     * @type {string}
     * @memberof Informations
     */
    deleted_at?: string;
    /**
     *
     * @type {string}
     * @memberof Informations
     */
    created_at?: string;
    /**
     *
     * @type {string}
     * @memberof Informations
     */
    updated_at?: string;
    /**
     * push済かどうか
     * @type {number}
     * @memberof Informations
     */
    pushed: number;
}

/**
 * 項目ごとのエラーレスポンス
 * @export
 * @interface ItemErrorResponse
 */
export class ItemErrorResponse {
    /**
     * エラーコード
     * @type {string}
     * @memberof ItemErrorResponse
     */
    code: string;
    /**
     * エラーメッセージ
     * @type {string}
     * @memberof ItemErrorResponse
     */
    message: string;
}

/**
 * 項目ごとのエラーレスポンスリスト
 * @export
 * @interface ItemErrorsResponse
 */
export class ItemErrorsResponse {
    /**
     * 項目ごとのエラーリスト
     * @type {{ [key: string]: Array&lt;ItemErrorResponse&gt;; }}
     * @memberof ItemErrorsResponse
     */
    itemErrors?: { [key: string]: Array<ItemErrorResponse>; };
}

/**
 * ログイン情報 リクエストフォーム
 * @export
 * @interface LoginIndexRequest
 */
export class LoginIndexRequest {
}

/**
 * ログイン情報 レスポンスフォーム
 * @export
 * @interface LoginIndexResponse
 */
export class LoginIndexResponse {
    /**
     * ユーザID
     * @type {string}
     * @memberof LoginIndexResponse
     */
    id?: string;
    /**
     * email
     * @type {string}
     * @memberof LoginIndexResponse
     */
    email?: string;
    /**
     * ユーザコード
     * @type {string}
     * @memberof LoginIndexResponse
     */
    userCode?: string;
    /**
     *
     * @type {number}
     * @memberof LoginIndexResponse
     */
    companyId?: number;
    /**
     *
     * @type {Companies}
     * @memberof LoginIndexResponse
     */
    company?: Companies;
    /**
     * 名前
     * @type {string}
     * @memberof LoginIndexResponse
     */
    name?: string;
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof LoginIndexResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof LoginIndexResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof LoginIndexResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 * ログイン リクエストフォーム
 * @export
 * @interface LoginLoginRequest
 */
export class LoginLoginRequest {
    /**
     * ユーザID
     * @type {string}
     * @memberof LoginLoginRequest
     */
    userCode?: string;
    /**
     * パスワード
     * @type {string}
     * @memberof LoginLoginRequest
     */
    password?: string;
}

/**
 * ログイン レスポンスフォーム
 * @export
 * @interface LoginLoginResponse
 */
export class LoginLoginResponse {
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof LoginLoginResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof LoginLoginResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof LoginLoginResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 * ログアウト リクエストフォーム
 * @export
 * @interface LoginLogoutRequest
 */
export class LoginLogoutRequest {
}

/**
 * ログアウト レスポンスフォーム
 * @export
 * @interface LoginLogoutResponse
 */
export class LoginLogoutResponse {
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof LoginLogoutResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof LoginLogoutResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof LoginLogoutResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 *
 * @export
 * @interface LongDistanceFlights
 */
export class LongDistanceFlights {
    /**
     *
     * @type {number}
     * @memberof LongDistanceFlights
     */
    id: number;
    /**
     * 便名
     * @type {string}
     * @memberof LongDistanceFlights
     */
    flightName: string;
    /**
     * 行き先方面
     * @type {string}
     * @memberof LongDistanceFlights
     */
    dest: string;
    /**
     * コードシェア便名1
     * @type {string}
     * @memberof LongDistanceFlights
     */
    code_share_name_1?: string;
    /**
     * コードシェア便名2
     * @type {string}
     * @memberof LongDistanceFlights
     */
    code_share_name_2?: string;
    /**
     * コードシェア便名3
     * @type {string}
     * @memberof LongDistanceFlights
     */
    code_share_name_3?: string;
    /**
     * コードシェア便名4
     * @type {string}
     * @memberof LongDistanceFlights
     */
    code_share_name_4?: string;
    /**
     * コードシェア便名5
     * @type {string}
     * @memberof LongDistanceFlights
     */
    code_share_name_5?: string;
    /**
     * 有効期限(開始)
     * @type {string}
     * @memberof LongDistanceFlights
     */
    expiration_start_dt?: string;
    /**
     * 有効期限(終了)
     * @type {string}
     * @memberof LongDistanceFlights
     */
    expiration_end_dt?: string;
    /**
     *
     * @type {string}
     * @memberof LongDistanceFlights
     */
    created_at?: string;
    /**
     *
     * @type {string}
     * @memberof LongDistanceFlights
     */
    updated_at?: string;
}

/**
 *
 * @export
 * @interface Magazines
 */
export class Magazines {
    /**
     *
     * @type {number}
     * @memberof Magazines
     */
    id: number;
    /**
     * 1: 公開、2: 非公開
     * @type {number}
     * @memberof Magazines
     */
    status: number;
    /**
     * 公開日
     * @type {string}
     * @memberof Magazines
     */
    publish_dt?: string;
    /**
     * タイトル
     * @type {string}
     * @memberof Magazines
     */
    title: string;
    /**
     * 見出し
     * @type {string}
     * @memberof Magazines
     */
    headline: string;
    /**
     * サムネイルのURL
     * @type {string}
     * @memberof Magazines
     */
    thumbnail_url?: string;
    /**
     * サムネイルのファイル名
     * @type {string}
     * @memberof Magazines
     */
    thumbnail_filename?: string;
    /**
     * PDFのURL
     * @type {string}
     * @memberof Magazines
     */
    pdf_url?: string;
    /**
     * PDFのファイル名
     * @type {string}
     * @memberof Magazines
     */
    pdf_filename?: string;
    /**
     *
     * @type {string}
     * @memberof Magazines
     */
    deleted_at?: string;
    /**
     *
     * @type {string}
     * @memberof Magazines
     */
    created_at?: string;
    /**
     *
     * @type {string}
     * @memberof Magazines
     */
    updated_at?: string;
}

/**
 * 会員誌ライブラリ削除 リクエストフォーム
 * @export
 * @interface MagazinesDeleteRequest
 */
export class MagazinesDeleteRequest {
    /**
     * ID
     * @type {number}
     * @memberof MagazinesDeleteRequest
     */
    id?: number;
}

/**
 * 会員誌ライブラリ削除 レスポンスフォーム
 * @export
 * @interface MagazinesDeleteResponse
 */
export class MagazinesDeleteResponse {
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof MagazinesDeleteResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof MagazinesDeleteResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof MagazinesDeleteResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 * 会員誌ライブラリ一覧 リクエストフォーム
 * @export
 * @interface MagazinesGetListRequest
 */
export class MagazinesGetListRequest {
    /**
     * ページングデータ
     * @type {PaginationRequest}
     * @memberof MagazinesGetListRequest
     */
    pagination?: PaginationRequest;
    /**
     * 検索条件
     * @type {MagazinesSearchRequest}
     * @memberof MagazinesGetListRequest
     */
    search?: MagazinesSearchRequest;
}

/**
 * 会員誌ライブラリ一覧 レスポンスフォーム
 * @export
 * @interface MagazinesGetListResponse
 */
export class MagazinesGetListResponse {
    /**
     * データリスト
     * @type {Array&lt;MagazinesGetListRowForm&gt;}
     * @memberof MagazinesGetListResponse
     */
    list?: Array<MagazinesGetListRowForm>;
    /**
     * 総合件数
     * @type {number}
     * @memberof MagazinesGetListResponse
     */
    total?: number;
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof MagazinesGetListResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof MagazinesGetListResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof MagazinesGetListResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 * 会員誌ライブラリ 行データ
 * @export
 * @interface MagazinesGetListRowForm
 */
export class MagazinesGetListRowForm {
    /**
     * 会員誌ライブラリ
     * @type {Magazines}
     * @memberof MagazinesGetListRowForm
     */
    magazine?: Magazines;
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof MagazinesGetListRowForm
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof MagazinesGetListRowForm
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof MagazinesGetListRowForm
     */
    validationErrors?: { [key: string]: string; };
}

/**
 * 会員誌ライブラリ登録 リクエストフォーム
 * @export
 * @interface MagazinesSaveRequest
 */
export class MagazinesSaveRequest {
    /**
     * 会員誌ライブラリ
     * @type {Magazines}
     * @memberof MagazinesSaveRequest
     */
    magazine?: Magazines;
}

/**
 * 会員誌ライブラリ登録 レスポンスフォーム
 * @export
 * @interface MagazinesSaveResponse
 */
export class MagazinesSaveResponse {
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof MagazinesSaveResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof MagazinesSaveResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof MagazinesSaveResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 *
 * @export
 * @interface MagazinesSearchRequest
 */
export class MagazinesSearchRequest {
    /**
     * ID
     * @type {number}
     * @memberof MagazinesSearchRequest
     */
    id?: number;
}

/**
 *
 * @export
 * @interface Migrations
 */
export class Migrations {
    /**
     *
     * @type {number}
     * @memberof Migrations
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof Migrations
     */
    migration: string;
    /**
     *
     * @type {number}
     * @memberof Migrations
     */
    batch: number;
}

/**
 * ページネーション
 * @export
 * @interface PaginationRequest
 */
export class PaginationRequest {
    /**
     * 現在ページ数
     * @type {number}
     * @memberof PaginationRequest
     */
    current: number;
    /**
     * 1ページあたり件数
     * @type {number}
     * @memberof PaginationRequest
     */
    pageSize: number;
}

/**
 *
 * @export
 * @interface PushNotificationDevices
 */
export class PushNotificationDevices {
    /**
     *
     * @type {number}
     * @memberof PushNotificationDevices
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof PushNotificationDevices
     */
    user_id?: number;
    /**
     * 1:iOS 2:Android
     * @type {number}
     * @memberof PushNotificationDevices
     */
    device_type?: number;
    /**
     * デバイストークン
     * @type {string}
     * @memberof PushNotificationDevices
     */
    device_token?: string;
    /**
     *
     * @type {string}
     * @memberof PushNotificationDevices
     */
    deleted_at?: string;
    /**
     *
     * @type {string}
     * @memberof PushNotificationDevices
     */
    created_at?: string;
    /**
     *
     * @type {string}
     * @memberof PushNotificationDevices
     */
    updated_at?: string;
}

/**
 *
 * @export
 * @interface SortRequest
 */
export class SortRequest {
    /**
     * ソート列
     * @type {string}
     * @memberof SortRequest
     */
    column?: string;
    /**
     * ソート順序
     * @type {string}
     * @memberof SortRequest
     */
    order?: string;
}

/**
 *
 * @export
 * @interface Users
 */
export class Users {
    /**
     * 企業コード
     * @type {number}
     * @memberof Users
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof Users
     */
    company_id?: number;
    /**
     * 会員コード
     * @type {string}
     * @memberof Users
     */
    user_code?: string;
    /**
     * パスワード
     * @type {string}
     * @memberof Users
     */
    password: string;
    /**
     * 名前
     * @type {string}
     * @memberof Users
     */
    name: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof Users
     */
    email: string;
    /**
     * ファストレーン合計使用回数
     * @type {string}
     * @memberof Users
     */
    fastlane_used_count?: string;
    /**
     * ラウンジ合計使用回数
     * @type {number}
     * @memberof Users
     */
    lounge_used_count?: number;
    /**
     * FastLane最新利用履歴 fastlane_histories.id
     * @type {number}
     * @memberof Users
     */
    last_f_history_id?: number;
    /**
     * ラウンジ最新利用履歴 fastlane_histories.id
     * @type {number}
     * @memberof Users
     */
    last_l_history_id?: number;
    /**
     *
     * @type {string}
     * @memberof Users
     */
    deleted_at?: string;
    /**
     *
     * @type {string}
     * @memberof Users
     */
    created_at?: string;
    /**
     *
     * @type {string}
     * @memberof Users
     */
    updated_at?: string;
    /**
     * パスワードリセットトークん
     * @type {string}
     * @memberof Users
     */
    reset_token?: string;
    /**
     * パスワードリセット有効期限
     * @type {string}
     * @memberof Users
     */
    reset_limit?: string;
    /**
     * ログイン失敗数
     * @type {number}
     * @memberof Users
     */
    error_count?: number;
    /**
     * ログイン失敗日時
     * @type {string}
     * @memberof Users
     */
    error_dt?: string;
}

/**
 * 全ユーザリセット リクエストフォーム
 * @export
 * @interface UsersAllUserResetRequest
 */
export class UsersAllUserResetRequest {
    /**
     * ID
     * @type {number}
     * @memberof UsersAllUserResetRequest
     */
    company_id?: number;
}

/**
 * 全ユーザリセット レスポンスフォーム
 * @export
 * @interface UsersAllUserResetResponse
 */
export class UsersAllUserResetResponse {
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof UsersAllUserResetResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof UsersAllUserResetResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof UsersAllUserResetResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 * ユーザ一覧 リクエストフォーム
 * @export
 * @interface UsersGetListRequest
 */
export class UsersGetListRequest {
    /**
     * ページングデータ
     * @type {PaginationRequest}
     * @memberof UsersGetListRequest
     */
    pagination?: PaginationRequest;
    /**
     * 検索条件
     * @type {UsersSearchRequest}
     * @memberof UsersGetListRequest
     */
    search?: UsersSearchRequest;
}

/**
 * ユーザ一覧 レスポンスフォーム
 * @export
 * @interface UsersGetListResponse
 */
export class UsersGetListResponse {
    /**
     * データリスト
     * @type {Array&lt;UsersGetListRowForm&gt;}
     * @memberof UsersGetListResponse
     */
    list?: Array<UsersGetListRowForm>;
    /**
     * 総合件数
     * @type {number}
     * @memberof UsersGetListResponse
     */
    total?: number;
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof UsersGetListResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof UsersGetListResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof UsersGetListResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 * 行データ
 * @export
 * @interface UsersGetListRowForm
 */
export class UsersGetListRowForm {
    /**
     *
     * @type {Users}
     * @memberof UsersGetListRowForm
     */
    users?: Users;
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof UsersGetListRowForm
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof UsersGetListRowForm
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof UsersGetListRowForm
     */
    validationErrors?: { [key: string]: string; };
}

/**
 *
 * @export
 * @interface UsersSearchRequest
 */
export class UsersSearchRequest {
    /**
     * ID
     * @type {number}
     * @memberof UsersSearchRequest
     */
    id?: number;
    /**
     *
     * @type {number}
     * @memberof UsersSearchRequest
     */
    company_id?: number;
}

/**
 * ユーザリセット リクエストフォーム
 * @export
 * @interface UsersUserResetRequest
 */
export class UsersUserResetRequest {
    /**
     * ID
     * @type {number}
     * @memberof UsersUserResetRequest
     */
    id?: number;
}

/**
 * ユーザリセット レスポンスフォーム
 * @export
 * @interface UsersUserResetResponse
 */
export class UsersUserResetResponse {
    /**
     * エラーレスポンスリスト
     * @type {Array&lt;ErrorResponse&gt;}
     * @memberof UsersUserResetResponse
     */
    errors?: Array<ErrorResponse>;
    /**
     * 項目ごとのエラーレスポンスリスト
     * @type {Array&lt;ItemErrorResponse&gt;}
     * @memberof UsersUserResetResponse
     */
    itemErrors?: Array<ItemErrorResponse>;
    /**
     * バリデーションエラーレスポンスリスト
     * @type {{ [key: string]: string; }}
     * @memberof UsersUserResetResponse
     */
    validationErrors?: { [key: string]: string; };
}

/**
 * バリデーションエラーレスポンスリスト
 * @export
 * @interface ValidationErrorsResponse
 */
export class ValidationErrorsResponse {
    /**
     * バリデーションエラーリスト
     * @type {{ [key: string]: string; }}
     * @memberof ValidationErrorsResponse
     */
    validationErrors?: { [key: string]: string; };
}


/**
 * AdminFileApi - fetch parameter creator
 * @export
 */
export const AdminFileApiFetchParamCreator = function (configuration?: Configuration) {
    configuration;  // dummy code
    return {
        /**
         *
         * @summary アップロード
         * @param {AdminFileUploadRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upload(body: AdminFileUploadRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling upload.');
            }
            const localVarPath = `/admin/file/upload`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AdminFileUploadRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminFileApi - functional programming interface
 * @export
 */
export const AdminFileApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary アップロード
         * @param {AdminFileUploadRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upload(body: AdminFileUploadRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminFileUploadResponse> {
            const localVarFetchArgs = AdminFileApiFetchParamCreator(configuration).upload(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AdminFileApi - factory interface
 * @export
 */
export const AdminFileApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary アップロード
         * @param {AdminFileUploadRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upload(body: AdminFileUploadRequest, options?: any) {
            return AdminFileApiFp(configuration).upload(body, options)(fetch, basePath);
        },
    };
};

/**
 * AdminFileApi - object-oriented interface
 * @export
 * @class AdminFileApi
 * @extends {BaseAPI}
 */
export class AdminFileApi extends BaseAPI {
    /**
     *
     * @summary アップロード
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFileApi
     */
    public upload(body: AdminFileUploadRequest, options?: any) {
        return AdminFileApiFp(this.configuration).upload(body, options)(this.fetch, this.basePath);
    }

}

/**
 * AdminLoginApi - fetch parameter creator
 * @export
 */
export const AdminLoginApiFetchParamCreator = function (configuration?: Configuration) {
    configuration;  // dummy code
    return {
        /**
         *
         * @summary Admin login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        index(options: any = {}): FetchArgs {
            const localVarPath = `/admin/login`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Admin login API
         * @summary Admin login
         * @param {AdminLoginStoreRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        store(body: AdminLoginStoreRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling store.');
            }
            const localVarPath = `/admin/login`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AdminLoginStoreRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminLoginApi - functional programming interface
 * @export
 */
export const AdminLoginApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Admin login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        index(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminLoginIndexResponse> {
            const localVarFetchArgs = AdminLoginApiFetchParamCreator(configuration).index(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Admin login API
         * @summary Admin login
         * @param {AdminLoginStoreRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        store(body: AdminLoginStoreRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminLoginStoreResponse> {
            const localVarFetchArgs = AdminLoginApiFetchParamCreator(configuration).store(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AdminLoginApi - factory interface
 * @export
 */
export const AdminLoginApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary Admin login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        index(options?: any) {
            return AdminLoginApiFp(configuration).index(options)(fetch, basePath);
        },
        /**
         * Admin login API
         * @summary Admin login
         * @param {AdminLoginStoreRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        store(body: AdminLoginStoreRequest, options?: any) {
            return AdminLoginApiFp(configuration).store(body, options)(fetch, basePath);
        },
    };
};

/**
 * AdminLoginApi - object-oriented interface
 * @export
 * @class AdminLoginApi
 * @extends {BaseAPI}
 */
export class AdminLoginApi extends BaseAPI {
    /**
     *
     * @summary Admin login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLoginApi
     */
    public index(options?: any) {
        return AdminLoginApiFp(this.configuration).index(options)(this.fetch, this.basePath);
    }

    /**
     * Admin login API
     * @summary Admin login
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLoginApi
     */
    public store(body: AdminLoginStoreRequest, options?: any) {
        return AdminLoginApiFp(this.configuration).store(body, options)(this.fetch, this.basePath);
    }

}

/**
 * AdminUsersApi - fetch parameter creator
 * @export
 */
export const AdminUsersApiFetchParamCreator = function (configuration?: Configuration) {
    configuration;  // dummy code
    return {
        /**
         *
         * @summary 管理者アカウント削除
         * @param {AdminUsersDeleteRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(body: AdminUsersDeleteRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling _delete.');
            }
            const localVarPath = `/admin/admin_users/admin_users/delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AdminUsersDeleteRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary 管理者アカウント一覧
         * @param {AdminUsersGetListRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(body: AdminUsersGetListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getList.');
            }
            const localVarPath = `/admin/admin_users/admin_users/get_list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AdminUsersGetListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary 管理者アカウント登録
         * @param {AdminUsersSaveRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(body: AdminUsersSaveRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling save.');
            }
            const localVarPath = `/admin/admin_users/admin_users/save`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AdminUsersSaveRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminUsersApi - functional programming interface
 * @export
 */
export const AdminUsersApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary 管理者アカウント削除
         * @param {AdminUsersDeleteRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(body: AdminUsersDeleteRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminUsersDeleteResponse> {
            const localVarFetchArgs = AdminUsersApiFetchParamCreator(configuration)._delete(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary 管理者アカウント一覧
         * @param {AdminUsersGetListRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(body: AdminUsersGetListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminUsersGetListResponse> {
            const localVarFetchArgs = AdminUsersApiFetchParamCreator(configuration).getList(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary 管理者アカウント登録
         * @param {AdminUsersSaveRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(body: AdminUsersSaveRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminUsersSaveResponse> {
            const localVarFetchArgs = AdminUsersApiFetchParamCreator(configuration).save(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AdminUsersApi - factory interface
 * @export
 */
export const AdminUsersApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary 管理者アカウント削除
         * @param {AdminUsersDeleteRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(body: AdminUsersDeleteRequest, options?: any) {
            return AdminUsersApiFp(configuration)._delete(body, options)(fetch, basePath);
        },
        /**
         *
         * @summary 管理者アカウント一覧
         * @param {AdminUsersGetListRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(body: AdminUsersGetListRequest, options?: any) {
            return AdminUsersApiFp(configuration).getList(body, options)(fetch, basePath);
        },
        /**
         *
         * @summary 管理者アカウント登録
         * @param {AdminUsersSaveRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(body: AdminUsersSaveRequest, options?: any) {
            return AdminUsersApiFp(configuration).save(body, options)(fetch, basePath);
        },
    };
};

/**
 * AdminUsersApi - object-oriented interface
 * @export
 * @class AdminUsersApi
 * @extends {BaseAPI}
 */
export class AdminUsersApi extends BaseAPI {
    /**
     *
     * @summary 管理者アカウント削除
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUsersApi
     */
    public _delete(body: AdminUsersDeleteRequest, options?: any) {
        return AdminUsersApiFp(this.configuration)._delete(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary 管理者アカウント一覧
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUsersApi
     */
    public getList(body: AdminUsersGetListRequest, options?: any) {
        return AdminUsersApiFp(this.configuration).getList(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary 管理者アカウント登録
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUsersApi
     */
    public save(body: AdminUsersSaveRequest, options?: any) {
        return AdminUsersApiFp(this.configuration).save(body, options)(this.fetch, this.basePath);
    }

}

/**
 * AuditingsApi - fetch parameter creator
 * @export
 */
export const AuditingsApiFetchParamCreator = function (configuration?: Configuration) {
    configuration;  // dummy code
    return {
        /**
         *
         * @summary データ更新履歴
         * @param {AuditingsGetListRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(body: AuditingsGetListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getList.');
            }
            const localVarPath = `/admin/auditings/auditings/get_list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AuditingsGetListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuditingsApi - functional programming interface
 * @export
 */
export const AuditingsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary データ更新履歴
         * @param {AuditingsGetListRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(body: AuditingsGetListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuditingsGetListResponse> {
            const localVarFetchArgs = AuditingsApiFetchParamCreator(configuration).getList(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuditingsApi - factory interface
 * @export
 */
export const AuditingsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary データ更新履歴
         * @param {AuditingsGetListRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(body: AuditingsGetListRequest, options?: any) {
            return AuditingsApiFp(configuration).getList(body, options)(fetch, basePath);
        },
    };
};

/**
 * AuditingsApi - object-oriented interface
 * @export
 * @class AuditingsApi
 * @extends {BaseAPI}
 */
export class AuditingsApi extends BaseAPI {
    /**
     *
     * @summary データ更新履歴
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditingsApi
     */
    public getList(body: AuditingsGetListRequest, options?: any) {
        return AuditingsApiFp(this.configuration).getList(body, options)(this.fetch, this.basePath);
    }

}

/**
 * BannersApi - fetch parameter creator
 * @export
 */
export const BannersApiFetchParamCreator = function (configuration?: Configuration) {
    configuration;  // dummy code
    return {
        /**
         *
         * @summary バナー削除
         * @param {BannersDeleteRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(body: BannersDeleteRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling _delete.');
            }
            const localVarPath = `/admin/banner/banner/delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BannersDeleteRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary バナー一覧
         * @param {BannersGetListRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(body: BannersGetListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getList.');
            }
            const localVarPath = `/admin/banner/banner/get_list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BannersGetListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary バナー登録
         * @param {BannersSaveRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(body: BannersSaveRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling save.');
            }
            const localVarPath = `/admin/banner/banner/save`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BannersSaveRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BannersApi - functional programming interface
 * @export
 */
export const BannersApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary バナー削除
         * @param {BannersDeleteRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(body: BannersDeleteRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BannersDeleteResponse> {
            const localVarFetchArgs = BannersApiFetchParamCreator(configuration)._delete(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary バナー一覧
         * @param {BannersGetListRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(body: BannersGetListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BannersGetListResponse> {
            const localVarFetchArgs = BannersApiFetchParamCreator(configuration).getList(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary バナー登録
         * @param {BannersSaveRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(body: BannersSaveRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BannersSaveResponse> {
            const localVarFetchArgs = BannersApiFetchParamCreator(configuration).save(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BannersApi - factory interface
 * @export
 */
export const BannersApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary バナー削除
         * @param {BannersDeleteRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(body: BannersDeleteRequest, options?: any) {
            return BannersApiFp(configuration)._delete(body, options)(fetch, basePath);
        },
        /**
         *
         * @summary バナー一覧
         * @param {BannersGetListRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(body: BannersGetListRequest, options?: any) {
            return BannersApiFp(configuration).getList(body, options)(fetch, basePath);
        },
        /**
         *
         * @summary バナー登録
         * @param {BannersSaveRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(body: BannersSaveRequest, options?: any) {
            return BannersApiFp(configuration).save(body, options)(fetch, basePath);
        },
    };
};

/**
 * BannersApi - object-oriented interface
 * @export
 * @class BannersApi
 * @extends {BaseAPI}
 */
export class BannersApi extends BaseAPI {
    /**
     *
     * @summary バナー削除
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannersApi
     */
    public _delete(body: BannersDeleteRequest, options?: any) {
        return BannersApiFp(this.configuration)._delete(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary バナー一覧
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannersApi
     */
    public getList(body: BannersGetListRequest, options?: any) {
        return BannersApiFp(this.configuration).getList(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary バナー登録
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannersApi
     */
    public save(body: BannersSaveRequest, options?: any) {
        return BannersApiFp(this.configuration).save(body, options)(this.fetch, this.basePath);
    }

}

/**
 * CompaniesApi - fetch parameter creator
 * @export
 */
export const CompaniesApiFetchParamCreator = function (configuration?: Configuration) {
    configuration;  // dummy code
    return {
        /**
         *
         * @summary 企業削除
         * @param {CompaniesDeleteRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(body: CompaniesDeleteRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling _delete.');
            }
            const localVarPath = `/admin/companies/companies/delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CompaniesDeleteRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary CSVダウンロード
         * @param {CompaniesCsvDownloadRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownload(body: CompaniesCsvDownloadRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling csvDownload.');
            }
            const localVarPath = `/admin/companies/companies/csv_download`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CompaniesCsvDownloadRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary 企業一覧
         * @param {CompaniesGetListRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(body: CompaniesGetListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getList.');
            }
            const localVarPath = `/admin/companies/companies/get_list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CompaniesGetListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary 企業更新
         * @param {CompaniesSaveRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(body: CompaniesSaveRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling save.');
            }
            const localVarPath = `/admin/companies/companies/save`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CompaniesSaveRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompaniesApi - functional programming interface
 * @export
 */
export const CompaniesApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary 企業削除
         * @param {CompaniesDeleteRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(body: CompaniesDeleteRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CompaniesDeleteResponse> {
            const localVarFetchArgs = CompaniesApiFetchParamCreator(configuration)._delete(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary CSVダウンロード
         * @param {CompaniesCsvDownloadRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownload(body: CompaniesCsvDownloadRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CompaniesCsvDownloadResponse> {
            const localVarFetchArgs = CompaniesApiFetchParamCreator(configuration).csvDownload(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary 企業一覧
         * @param {CompaniesGetListRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(body: CompaniesGetListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CompaniesGetListResponse> {
            const localVarFetchArgs = CompaniesApiFetchParamCreator(configuration).getList(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary 企業更新
         * @param {CompaniesSaveRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(body: CompaniesSaveRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CompaniesSaveResponse> {
            const localVarFetchArgs = CompaniesApiFetchParamCreator(configuration).save(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CompaniesApi - factory interface
 * @export
 */
export const CompaniesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary 企業削除
         * @param {CompaniesDeleteRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(body: CompaniesDeleteRequest, options?: any) {
            return CompaniesApiFp(configuration)._delete(body, options)(fetch, basePath);
        },
        /**
         *
         * @summary CSVダウンロード
         * @param {CompaniesCsvDownloadRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownload(body: CompaniesCsvDownloadRequest, options?: any) {
            return CompaniesApiFp(configuration).csvDownload(body, options)(fetch, basePath);
        },
        /**
         *
         * @summary 企業一覧
         * @param {CompaniesGetListRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(body: CompaniesGetListRequest, options?: any) {
            return CompaniesApiFp(configuration).getList(body, options)(fetch, basePath);
        },
        /**
         *
         * @summary 企業更新
         * @param {CompaniesSaveRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(body: CompaniesSaveRequest, options?: any) {
            return CompaniesApiFp(configuration).save(body, options)(fetch, basePath);
        },
    };
};

/**
 * CompaniesApi - object-oriented interface
 * @export
 * @class CompaniesApi
 * @extends {BaseAPI}
 */
export class CompaniesApi extends BaseAPI {
    /**
     *
     * @summary 企業削除
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public _delete(body: CompaniesDeleteRequest, options?: any) {
        return CompaniesApiFp(this.configuration)._delete(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary CSVダウンロード
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public csvDownload(body: CompaniesCsvDownloadRequest, options?: any) {
        return CompaniesApiFp(this.configuration).csvDownload(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary 企業一覧
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public getList(body: CompaniesGetListRequest, options?: any) {
        return CompaniesApiFp(this.configuration).getList(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary 企業更新
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public save(body: CompaniesSaveRequest, options?: any) {
        return CompaniesApiFp(this.configuration).save(body, options)(this.fetch, this.basePath);
    }

}

/**
 * CouponTabsApi - fetch parameter creator
 * @export
 */
export const CouponTabsApiFetchParamCreator = function (configuration?: Configuration) {
    configuration;  // dummy code
    return {
        /**
         *
         * @summary クーポンタブ削除
         * @param {CouponTabsDeleteRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(body: CouponTabsDeleteRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling _delete.');
            }
            const localVarPath = `/admin/coupon_tabs/coupon_tabs/delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CouponTabsDeleteRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary クーポンタブ一覧
         * @param {CouponTabsGetListRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(body: CouponTabsGetListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getList.');
            }
            const localVarPath = `/admin/coupon_tabs/coupon_tabs/get_list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CouponTabsGetListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary クーポンタブ登録
         * @param {CouponTabsSaveRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(body: CouponTabsSaveRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling save.');
            }
            const localVarPath = `/admin/coupon_tabs/coupon_tabs/save`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CouponTabsSaveRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CouponTabsApi - functional programming interface
 * @export
 */
export const CouponTabsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary クーポンタブ削除
         * @param {CouponTabsDeleteRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(body: CouponTabsDeleteRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CouponTabsDeleteResponse> {
            const localVarFetchArgs = CouponTabsApiFetchParamCreator(configuration)._delete(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary クーポンタブ一覧
         * @param {CouponTabsGetListRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(body: CouponTabsGetListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CouponTabsGetListResponse> {
            const localVarFetchArgs = CouponTabsApiFetchParamCreator(configuration).getList(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary クーポンタブ登録
         * @param {CouponTabsSaveRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(body: CouponTabsSaveRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CouponTabsSaveResponse> {
            const localVarFetchArgs = CouponTabsApiFetchParamCreator(configuration).save(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CouponTabsApi - factory interface
 * @export
 */
export const CouponTabsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary クーポンタブ削除
         * @param {CouponTabsDeleteRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(body: CouponTabsDeleteRequest, options?: any) {
            return CouponTabsApiFp(configuration)._delete(body, options)(fetch, basePath);
        },
        /**
         *
         * @summary クーポンタブ一覧
         * @param {CouponTabsGetListRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(body: CouponTabsGetListRequest, options?: any) {
            return CouponTabsApiFp(configuration).getList(body, options)(fetch, basePath);
        },
        /**
         *
         * @summary クーポンタブ登録
         * @param {CouponTabsSaveRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(body: CouponTabsSaveRequest, options?: any) {
            return CouponTabsApiFp(configuration).save(body, options)(fetch, basePath);
        },
    };
};

/**
 * CouponTabsApi - object-oriented interface
 * @export
 * @class CouponTabsApi
 * @extends {BaseAPI}
 */
export class CouponTabsApi extends BaseAPI {
    /**
     *
     * @summary クーポンタブ削除
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponTabsApi
     */
    public _delete(body: CouponTabsDeleteRequest, options?: any) {
        return CouponTabsApiFp(this.configuration)._delete(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary クーポンタブ一覧
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponTabsApi
     */
    public getList(body: CouponTabsGetListRequest, options?: any) {
        return CouponTabsApiFp(this.configuration).getList(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary クーポンタブ登録
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponTabsApi
     */
    public save(body: CouponTabsSaveRequest, options?: any) {
        return CouponTabsApiFp(this.configuration).save(body, options)(this.fetch, this.basePath);
    }

}

/**
 * CouponsApi - fetch parameter creator
 * @export
 */
export const CouponsApiFetchParamCreator = function (configuration?: Configuration) {
    configuration;  // dummy code
    return {
        /**
         *
         * @summary クーポン削除
         * @param {CouponsDeleteRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(body: CouponsDeleteRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling _delete.');
            }
            const localVarPath = `/admin/coupons/coupons/delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CouponsDeleteRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary クーポン一覧
         * @param {CouponsGetListRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(body: CouponsGetListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getList.');
            }
            const localVarPath = `/admin/coupons/coupons/get_list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CouponsGetListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary クーポン登録
         * @param {CouponsSaveRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(body: CouponsSaveRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling save.');
            }
            const localVarPath = `/admin/coupons/coupons/save`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CouponsSaveRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CouponsApi - functional programming interface
 * @export
 */
export const CouponsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary クーポン削除
         * @param {CouponsDeleteRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(body: CouponsDeleteRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CouponsDeleteResponse> {
            const localVarFetchArgs = CouponsApiFetchParamCreator(configuration)._delete(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary クーポン一覧
         * @param {CouponsGetListRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(body: CouponsGetListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CouponsGetListResponse> {
            const localVarFetchArgs = CouponsApiFetchParamCreator(configuration).getList(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary クーポン登録
         * @param {CouponsSaveRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(body: CouponsSaveRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CouponsSaveResponse> {
            const localVarFetchArgs = CouponsApiFetchParamCreator(configuration).save(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CouponsApi - factory interface
 * @export
 */
export const CouponsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary クーポン削除
         * @param {CouponsDeleteRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(body: CouponsDeleteRequest, options?: any) {
            return CouponsApiFp(configuration)._delete(body, options)(fetch, basePath);
        },
        /**
         *
         * @summary クーポン一覧
         * @param {CouponsGetListRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(body: CouponsGetListRequest, options?: any) {
            return CouponsApiFp(configuration).getList(body, options)(fetch, basePath);
        },
        /**
         *
         * @summary クーポン登録
         * @param {CouponsSaveRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(body: CouponsSaveRequest, options?: any) {
            return CouponsApiFp(configuration).save(body, options)(fetch, basePath);
        },
    };
};

/**
 * CouponsApi - object-oriented interface
 * @export
 * @class CouponsApi
 * @extends {BaseAPI}
 */
export class CouponsApi extends BaseAPI {
    /**
     *
     * @summary クーポン削除
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public _delete(body: CouponsDeleteRequest, options?: any) {
        return CouponsApiFp(this.configuration)._delete(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary クーポン一覧
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public getList(body: CouponsGetListRequest, options?: any) {
        return CouponsApiFp(this.configuration).getList(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary クーポン登録
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public save(body: CouponsSaveRequest, options?: any) {
        return CouponsApiFp(this.configuration).save(body, options)(this.fetch, this.basePath);
    }

}

/**
 * HistoriesApi - fetch parameter creator
 * @export
 */
export const HistoriesApiFetchParamCreator = function (configuration?: Configuration) {
    configuration;  // dummy code
    return {
        /**
         *
         * @summary キャンセル
         * @param {BaseApiRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelHistory(body: BaseApiRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling cancelHistory.');
            }
            const localVarPath = `/admin/histories/histories/cancel_history`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BaseApiRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary CSVダウンロード
         * @param {HistoriesCsvDownloadRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownload(body: HistoriesCsvDownloadRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling csvDownload.');
            }
            const localVarPath = `/admin/histories/histories/csv_download`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"HistoriesCsvDownloadRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary CSVダウンロード
         * @param {HistoriesCsvDownloadRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownload_1(body: HistoriesCsvDownloadRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling csvDownload_1.');
            }
            const localVarPath = `/admin/histories/histories/user_csv_download`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"HistoriesCsvDownloadRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary 利用履歴
         * @param {HistoriesGetListRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(body: HistoriesGetListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getList.');
            }
            const localVarPath = `/admin/histories/histories/get_list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"HistoriesGetListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HistoriesApi - functional programming interface
 * @export
 */
export const HistoriesApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary キャンセル
         * @param {BaseApiRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelHistory(body: BaseApiRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BaseApiResponse> {
            const localVarFetchArgs = HistoriesApiFetchParamCreator(configuration).cancelHistory(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary CSVダウンロード
         * @param {HistoriesCsvDownloadRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownload(body: HistoriesCsvDownloadRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HistoriesCsvDownloadResponse> {
            const localVarFetchArgs = HistoriesApiFetchParamCreator(configuration).csvDownload(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary CSVダウンロード
         * @param {HistoriesCsvDownloadRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownload_1(body: HistoriesCsvDownloadRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HistoriesCsvDownloadResponse> {
            const localVarFetchArgs = HistoriesApiFetchParamCreator(configuration).csvDownload_1(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary 利用履歴
         * @param {HistoriesGetListRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(body: HistoriesGetListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HistoriesGetListResponse> {
            const localVarFetchArgs = HistoriesApiFetchParamCreator(configuration).getList(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * HistoriesApi - factory interface
 * @export
 */
export const HistoriesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary キャンセル
         * @param {BaseApiRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelHistory(body: BaseApiRequest, options?: any) {
            return HistoriesApiFp(configuration).cancelHistory(body, options)(fetch, basePath);
        },
        /**
         *
         * @summary CSVダウンロード
         * @param {HistoriesCsvDownloadRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownload(body: HistoriesCsvDownloadRequest, options?: any) {
            return HistoriesApiFp(configuration).csvDownload(body, options)(fetch, basePath);
        },
        /**
         *
         * @summary CSVダウンロード
         * @param {HistoriesCsvDownloadRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownload_1(body: HistoriesCsvDownloadRequest, options?: any) {
            return HistoriesApiFp(configuration).csvDownload_1(body, options)(fetch, basePath);
        },
        /**
         *
         * @summary 利用履歴
         * @param {HistoriesGetListRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(body: HistoriesGetListRequest, options?: any) {
            return HistoriesApiFp(configuration).getList(body, options)(fetch, basePath);
        },
    };
};

/**
 * HistoriesApi - object-oriented interface
 * @export
 * @class HistoriesApi
 * @extends {BaseAPI}
 */
export class HistoriesApi extends BaseAPI {
    /**
     *
     * @summary キャンセル
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoriesApi
     */
    public cancelHistory(body: BaseApiRequest, options?: any) {
        return HistoriesApiFp(this.configuration).cancelHistory(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary CSVダウンロード
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoriesApi
     */
    public csvDownload(body: HistoriesCsvDownloadRequest, options?: any) {
        return HistoriesApiFp(this.configuration).csvDownload(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary CSVダウンロード
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoriesApi
     */
    public csvDownload_1(body: HistoriesCsvDownloadRequest, options?: any) {
        return HistoriesApiFp(this.configuration).csvDownload_1(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary 利用履歴
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoriesApi
     */
    public getList(body: HistoriesGetListRequest, options?: any) {
        return HistoriesApiFp(this.configuration).getList(body, options)(this.fetch, this.basePath);
    }

}

/**
 * InformationApi - fetch parameter creator
 * @export
 */
export const InformationApiFetchParamCreator = function (configuration?: Configuration) {
    configuration;  // dummy code
    return {
        /**
         *
         * @summary お知らせ削除
         * @param {InformationDeleteRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(body: InformationDeleteRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling _delete.');
            }
            const localVarPath = `/admin/information/information/delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InformationDeleteRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary CSVダウンロード
         * @param {InformationCsvDownloadRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownload(body: InformationCsvDownloadRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling csvDownload.');
            }
            const localVarPath = `/admin/information/information/csv_download`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InformationCsvDownloadRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary お知らせ一覧
         * @param {InformationGetListRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(body: InformationGetListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getList.');
            }
            const localVarPath = `/admin/information/information/get_list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InformationGetListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary お知らせ更新
         * @param {InformationSaveRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(body: InformationSaveRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling save.');
            }
            const localVarPath = `/admin/information/information/save`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InformationSaveRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InformationApi - functional programming interface
 * @export
 */
export const InformationApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary お知らせ削除
         * @param {InformationDeleteRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(body: InformationDeleteRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InformationDeleteResponse> {
            const localVarFetchArgs = InformationApiFetchParamCreator(configuration)._delete(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary CSVダウンロード
         * @param {InformationCsvDownloadRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownload(body: InformationCsvDownloadRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InformationCsvDownloadResponse> {
            const localVarFetchArgs = InformationApiFetchParamCreator(configuration).csvDownload(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary お知らせ一覧
         * @param {InformationGetListRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(body: InformationGetListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InformationGetListResponse> {
            const localVarFetchArgs = InformationApiFetchParamCreator(configuration).getList(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary お知らせ更新
         * @param {InformationSaveRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(body: InformationSaveRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InformationSaveResponse> {
            const localVarFetchArgs = InformationApiFetchParamCreator(configuration).save(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * InformationApi - factory interface
 * @export
 */
export const InformationApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary お知らせ削除
         * @param {InformationDeleteRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(body: InformationDeleteRequest, options?: any) {
            return InformationApiFp(configuration)._delete(body, options)(fetch, basePath);
        },
        /**
         *
         * @summary CSVダウンロード
         * @param {InformationCsvDownloadRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownload(body: InformationCsvDownloadRequest, options?: any) {
            return InformationApiFp(configuration).csvDownload(body, options)(fetch, basePath);
        },
        /**
         *
         * @summary お知らせ一覧
         * @param {InformationGetListRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(body: InformationGetListRequest, options?: any) {
            return InformationApiFp(configuration).getList(body, options)(fetch, basePath);
        },
        /**
         *
         * @summary お知らせ更新
         * @param {InformationSaveRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(body: InformationSaveRequest, options?: any) {
            return InformationApiFp(configuration).save(body, options)(fetch, basePath);
        },
    };
};

/**
 * InformationApi - object-oriented interface
 * @export
 * @class InformationApi
 * @extends {BaseAPI}
 */
export class InformationApi extends BaseAPI {
    /**
     *
     * @summary お知らせ削除
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InformationApi
     */
    public _delete(body: InformationDeleteRequest, options?: any) {
        return InformationApiFp(this.configuration)._delete(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary CSVダウンロード
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InformationApi
     */
    public csvDownload(body: InformationCsvDownloadRequest, options?: any) {
        return InformationApiFp(this.configuration).csvDownload(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary お知らせ一覧
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InformationApi
     */
    public getList(body: InformationGetListRequest, options?: any) {
        return InformationApiFp(this.configuration).getList(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary お知らせ更新
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InformationApi
     */
    public save(body: InformationSaveRequest, options?: any) {
        return InformationApiFp(this.configuration).save(body, options)(this.fetch, this.basePath);
    }

}

/**
 * LoginApi - fetch parameter creator
 * @export
 */
export const LoginApiFetchParamCreator = function (configuration?: Configuration) {
    configuration;  // dummy code
    return {
        /**
         *
         * @summary ログイン情報
         * @param {LoginIndexRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        index(body: LoginIndexRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling index.');
            }
            const localVarPath = `/kix_gbc/login/login/index`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LoginIndexRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary ログイン
         * @param {LoginLoginRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(body: LoginLoginRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling login.');
            }
            const localVarPath = `/kix_gbc/login/login/login`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LoginLoginRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary ログアウト
         * @param {LoginLogoutRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(body: LoginLogoutRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling logout.');
            }
            const localVarPath = `/kix_gbc/login/login/logout`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LoginLogoutRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoginApi - functional programming interface
 * @export
 */
export const LoginApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary ログイン情報
         * @param {LoginIndexRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        index(body: LoginIndexRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LoginIndexResponse> {
            const localVarFetchArgs = LoginApiFetchParamCreator(configuration).index(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary ログイン
         * @param {LoginLoginRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(body: LoginLoginRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LoginLoginResponse> {
            const localVarFetchArgs = LoginApiFetchParamCreator(configuration).login(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary ログアウト
         * @param {LoginLogoutRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(body: LoginLogoutRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LoginLogoutResponse> {
            const localVarFetchArgs = LoginApiFetchParamCreator(configuration).logout(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * LoginApi - factory interface
 * @export
 */
export const LoginApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary ログイン情報
         * @param {LoginIndexRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        index(body: LoginIndexRequest, options?: any) {
            return LoginApiFp(configuration).index(body, options)(fetch, basePath);
        },
        /**
         *
         * @summary ログイン
         * @param {LoginLoginRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(body: LoginLoginRequest, options?: any) {
            return LoginApiFp(configuration).login(body, options)(fetch, basePath);
        },
        /**
         *
         * @summary ログアウト
         * @param {LoginLogoutRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(body: LoginLogoutRequest, options?: any) {
            return LoginApiFp(configuration).logout(body, options)(fetch, basePath);
        },
    };
};

/**
 * LoginApi - object-oriented interface
 * @export
 * @class LoginApi
 * @extends {BaseAPI}
 */
export class LoginApi extends BaseAPI {
    /**
     *
     * @summary ログイン情報
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public index(body: LoginIndexRequest, options?: any) {
        return LoginApiFp(this.configuration).index(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary ログイン
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public login(body: LoginLoginRequest, options?: any) {
        return LoginApiFp(this.configuration).login(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary ログアウト
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public logout(body: LoginLogoutRequest, options?: any) {
        return LoginApiFp(this.configuration).logout(body, options)(this.fetch, this.basePath);
    }

}

/**
 * MagazinesApi - fetch parameter creator
 * @export
 */
export const MagazinesApiFetchParamCreator = function (configuration?: Configuration) {
    configuration;  // dummy code
    return {
        /**
         *
         * @summary 会員誌ライブラリ削除
         * @param {MagazinesDeleteRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(body: MagazinesDeleteRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling _delete.');
            }
            const localVarPath = `/admin/magazines/magazines/delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MagazinesDeleteRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary 会員誌ライブラリ一覧
         * @param {MagazinesGetListRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(body: MagazinesGetListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getList.');
            }
            const localVarPath = `/admin/magazines/magazines/get_list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MagazinesGetListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary 会員誌ライブラリ登録
         * @param {MagazinesSaveRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(body: MagazinesSaveRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling save.');
            }
            const localVarPath = `/admin/magazines/magazines/save`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MagazinesSaveRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MagazinesApi - functional programming interface
 * @export
 */
export const MagazinesApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary 会員誌ライブラリ削除
         * @param {MagazinesDeleteRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(body: MagazinesDeleteRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MagazinesDeleteResponse> {
            const localVarFetchArgs = MagazinesApiFetchParamCreator(configuration)._delete(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary 会員誌ライブラリ一覧
         * @param {MagazinesGetListRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(body: MagazinesGetListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MagazinesGetListResponse> {
            const localVarFetchArgs = MagazinesApiFetchParamCreator(configuration).getList(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary 会員誌ライブラリ登録
         * @param {MagazinesSaveRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(body: MagazinesSaveRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MagazinesSaveResponse> {
            const localVarFetchArgs = MagazinesApiFetchParamCreator(configuration).save(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * MagazinesApi - factory interface
 * @export
 */
export const MagazinesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary 会員誌ライブラリ削除
         * @param {MagazinesDeleteRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(body: MagazinesDeleteRequest, options?: any) {
            return MagazinesApiFp(configuration)._delete(body, options)(fetch, basePath);
        },
        /**
         *
         * @summary 会員誌ライブラリ一覧
         * @param {MagazinesGetListRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(body: MagazinesGetListRequest, options?: any) {
            return MagazinesApiFp(configuration).getList(body, options)(fetch, basePath);
        },
        /**
         *
         * @summary 会員誌ライブラリ登録
         * @param {MagazinesSaveRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(body: MagazinesSaveRequest, options?: any) {
            return MagazinesApiFp(configuration).save(body, options)(fetch, basePath);
        },
    };
};

/**
 * MagazinesApi - object-oriented interface
 * @export
 * @class MagazinesApi
 * @extends {BaseAPI}
 */
export class MagazinesApi extends BaseAPI {
    /**
     *
     * @summary 会員誌ライブラリ削除
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MagazinesApi
     */
    public _delete(body: MagazinesDeleteRequest, options?: any) {
        return MagazinesApiFp(this.configuration)._delete(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary 会員誌ライブラリ一覧
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MagazinesApi
     */
    public getList(body: MagazinesGetListRequest, options?: any) {
        return MagazinesApiFp(this.configuration).getList(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary 会員誌ライブラリ登録
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MagazinesApi
     */
    public save(body: MagazinesSaveRequest, options?: any) {
        return MagazinesApiFp(this.configuration).save(body, options)(this.fetch, this.basePath);
    }

}

/**
 * UsersApi - fetch parameter creator
 * @export
 */
export const UsersApiFetchParamCreator = function (configuration?: Configuration) {
    configuration;  // dummy code
    return {
        /**
         *
         * @summary 全ユーザリセット
         * @param {UsersAllUserResetRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUserReset(body: UsersAllUserResetRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling allUserReset.');
            }
            const localVarPath = `/admin/users/users/all_user_reset`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UsersAllUserResetRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary ユーザ一覧
         * @param {UsersGetListRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(body: UsersGetListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getList.');
            }
            const localVarPath = `/admin/users/users/get_list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UsersGetListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary ユーザリセット
         * @param {UsersUserResetRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userReset(body: UsersUserResetRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling userReset.');
            }
            const localVarPath = `/admin/users/users/user_reset`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST', cors: true, credentials: "include" }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UsersUserResetRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary 全ユーザリセット
         * @param {UsersAllUserResetRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUserReset(body: UsersAllUserResetRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UsersAllUserResetResponse> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).allUserReset(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary ユーザ一覧
         * @param {UsersGetListRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(body: UsersGetListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UsersGetListResponse> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).getList(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary ユーザリセット
         * @param {UsersUserResetRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userReset(body: UsersUserResetRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UsersUserResetResponse> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).userReset(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary 全ユーザリセット
         * @param {UsersAllUserResetRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUserReset(body: UsersAllUserResetRequest, options?: any) {
            return UsersApiFp(configuration).allUserReset(body, options)(fetch, basePath);
        },
        /**
         *
         * @summary ユーザ一覧
         * @param {UsersGetListRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(body: UsersGetListRequest, options?: any) {
            return UsersApiFp(configuration).getList(body, options)(fetch, basePath);
        },
        /**
         *
         * @summary ユーザリセット
         * @param {UsersUserResetRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userReset(body: UsersUserResetRequest, options?: any) {
            return UsersApiFp(configuration).userReset(body, options)(fetch, basePath);
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     *
     * @summary 全ユーザリセット
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public allUserReset(body: UsersAllUserResetRequest, options?: any) {
        return UsersApiFp(this.configuration).allUserReset(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary ユーザ一覧
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getList(body: UsersGetListRequest, options?: any) {
        return UsersApiFp(this.configuration).getList(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary ユーザリセット
     * @param {} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userReset(body: UsersUserResetRequest, options?: any) {
        return UsersApiFp(this.configuration).userReset(body, options)(this.fetch, this.basePath);
    }

}

