import * as React from 'react'
import {HashRouter as Router, Route, Switch} from 'react-router-dom'
import {globalStore} from "../../src/store/GlobalStore";
import {getRouteMap} from "../../src/utils/react/routerUtil";
import asyncComponent from "../../src/router/asyncComponent";

// const Home = asyncComponent(() => import('./../views/Home').then(mod => mod.default))
const Login = asyncComponent(() => import('./../views/Login').then(mod => mod.default));
const Member = asyncComponent(() => import('./../views/Member').then(mod => mod.default));
const InformationDetail = asyncComponent(() => import('./../views/Information/detail').then(mod => mod.default));
const UseFastlane = asyncComponent(() => import('./../views/UseFastlane').then(mod => mod.default));
const PermitError = asyncComponent(() => import('./../views/PermitError').then(mod => mod.default));
const DisplayPermit = asyncComponent(() => import('./../views/DisplayPermit').then(mod => mod.default));
const MyPage = asyncComponent(() => import('./../views/MyPage').then(mod => mod.default));
const PasswordReset = asyncComponent(() => import('./../views/PasswordReset').then(mod => mod.default));
const PasswordResetExec = asyncComponent(() => import('./../views/PasswordResetExec').then(mod => mod.default));
const FirstSetting = asyncComponent(() => import('./../views/FirstSetting').then(mod => mod.default));
const FirstSettingExec = asyncComponent(() => import('./../views/FirstSettingExec').then(mod => mod.default));
const Coupons = asyncComponent(() => import('./../views/Coupons').then(mod => mod.default));
const CouponDetail = asyncComponent(() => import('./../views/Coupons/Detail').then(mod => mod.default));
asyncComponent(() => import('./../../src/views/_Dummy').then(mod => mod.default));

const routings = (
  <>
    <Route exact path="/login" component={Login} title={"ログイン"}/>
    <Route exact path="/" component={Member} title={"メンバートップ"}/>
    <Route exact path="/information_detail/:id" component={InformationDetail} title={"お知らせ詳細"}/>
    <Route exact path="/use_fastlane" component={UseFastlane} title={"ファストレーンを使用する"}/>
    <Route exact path="/permit_error" component={PermitError} title={"エラー"}/>
    <Route exact path="/display_permit" component={DisplayPermit} title={""}/>
    <Route exact path="/my_page" component={MyPage} title={""}/>
    <Route exact path="/password_reset" component={PasswordReset} title={""}/>
    <Route exact path="/password_reset_exec" component={PasswordResetExec} title={""}/>
    <Route exact path="/first_setting" component={FirstSetting} title={""}/>
    <Route exact path="/first_setting_exec" component={FirstSettingExec} title={""}/>
    <Route exact path="/coupons" component={Coupons} title={"クーポン"}/>
    <Route exact path="/coupons/detail/:id" component={CouponDetail} title={"クーポン詳細"}/>
    {/*<Route exact path="/" component={Companies} title={"マイページ"}/>*/}
    <Route component={Member}/>
  </>
);

const App = () => (
  <Router>
    <div id="routerRoot">
      <Switch>
        {routings.props.children}
      </Switch>
    </div>
  </Router>
);
globalStore.routeMap = getRouteMap(routings);

export default App
