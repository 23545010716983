import required from './required';
import whitespace from './whitespace';
import type from './type';
import range from './range';
import enumRule from './enum';
import pattern from './pattern';

export default {
  required: required,
  whitespace: whitespace,
  type: type,
  range: range,
  'enum': enumRule,
  pattern: pattern
};