import {RouteComponentProps} from "react-router";
import {RouteMap} from "../types/router/route";

export class GlobalStore {
  // メニューの開閉状態
  collapsed = false;

  // ルーティング中の状況(react-router から渡されるもの)
  routing: RouteComponentProps<any>;

  // ルーティング一覧
  routeMap: RouteMap = {};

  static setRouting<ROUTE_PARAMS_STATIC>(props: RouteComponentProps<ROUTE_PARAMS_STATIC>) {
    const {location, history, match} = props;
    globalStore.routing = {location, history, match}
  }

  fetchingNotices = false;
  notices = [];
}

export const globalStore = new GlobalStore();

