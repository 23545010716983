import * as React from "react";
import {RouteComponentProps} from "react-router";
import {globalStore} from "../store/GlobalStore";
import {errorsStore} from "../store/common/Errors/ErrorsStore";
import {runInAction} from "mobx";

type ViewBaseProps<P, ROUTE_PARAMS> = P & RouteComponentProps<ROUTE_PARAMS>;

export class ViewBaseComponent<P, S, ROUTE_PARAMS = {}> extends React.Component<ViewBaseProps<P, ROUTE_PARAMS>, S> {
  constructor(props: ViewBaseProps<P, ROUTE_PARAMS>, context?: any) {
    const {location, history, match} = props;
    globalStore.routing = {location, history, match}

    super(props, context);
    runInAction(() => {
      errorsStore.errorMap = new Map([["default", []]]); // エラーが表示されたまま画面遷移すると引き継いでしまうためクリア
    });
  }
}
