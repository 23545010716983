import '../node_modules/normalize.css/normalize.css'
import './../src/styles/common.scss'
import './assets/css/common.scss'
import './assets/css/sub.scss'

import * as React from 'react'
import * as ReactDOM from 'react-dom'
import {Provider} from 'mobx-react'
import {useStrict} from 'mobx'

import App from './router'
import store from './store'
import {ReactComponentConstructor} from "../src/types/react";

useStrict(true)

const render = (Component: ReactComponentConstructor) => {
  ReactDOM.render(
    <Provider {...store}>
      <Component />
    </Provider>,
    document.getElementById('app') as HTMLElement
  )
}

render(App)

// Hot Module Replacement API
// if (module.hot) { }
