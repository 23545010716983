import {loginUserStore} from "../../store/Login/LoginUserStore";

export function adminApiErrorHandling(err: any): Promise<any> {
  if (err.status == 403) {
    loginUserStore.logout();
    return Promise.resolve(err);
  }

  return Promise.reject(err);
}
