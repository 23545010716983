import {ErrorResponse} from "../../../swagger";
import {action, observable} from "mobx";

export type ErrorMap = Map<string, ErrorResponse[]>

export class ErrorsStore {
  @observable
  public errorMap: ErrorMap = new Map([["default", []]]);

  @action
  clearErrors(key: string) {
    this.errorMap.set(key, []);
  }
}

export const errorsStore = new ErrorsStore();

