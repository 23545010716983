import moment from "moment";

type MomentOrStr = moment.Moment | string | null | undefined;

export function toDateTimeStr(dateTime: MomentOrStr): string| null {
  if (!dateTime) {
    return null;
  }
  return moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
}

export function toDateStr(dateTime: MomentOrStr): string| null {
  if (!dateTime) {
    return null;
  }
  return moment(dateTime).format("YYYY-MM-DD");
}

export function toHM(dateTime: MomentOrStr): string| null {
  if (!dateTime) {
    return null;
  }
  return moment(dateTime).format("HH:mm");
}

/**
 * HH:MM形式のデータを分の合計に変換
 * @param {MomentOrStr} dateTime
 * @returns {number | null}
 */
export function hmToMinutes(dateTime: MomentOrStr): number| null {
  if (!dateTime) {
    return null;
  }
  dateTime = moment(dateTime);
  return dateTime.hours() * 60 + dateTime.minutes();
}

