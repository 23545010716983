import {ErrorResponse} from "../../../swagger/index";
import {adminApiErrorHandling} from "../../Admin/admin";
import {errorsStore} from "../../../store/common/Errors/ErrorsStore";
import {runInAction} from "mobx";

export function adminApiWrapper(apiFunc: () => Promise<any>, errorCategory: string | null = null) {
  errorsStore.clearErrors("connection");
  return apiFunc().catch((err) => {
    return adminApiErrorHandling(err);
  }).catch(async (err) => {
    let json: any;
    if (err && "json" in err) {
      json = await err.json();
    }

    return runInAction(() => {
      // エラー内容がレスポンスに含まれる場合エラーストアに詰める
      if (json && "errors" in json && json.errors.length) {
        if (errorCategory) {
          errorsStore.errorMap.set(errorCategory, json.errors);
        }
        return Promise.reject(err);
      }

      // 通信自体が失敗した場合
      let errorResponse = new ErrorResponse();
      errorResponse.code = "CONNECTION_REFUSED";
      errorResponse.message = "サーバとの通信に失敗しました。";
      errorsStore.errorMap.get("connection")!.push(errorResponse);
      console.log(err);
      return Promise.reject(err);
    });
  }).then(async (json) => {
    if (json && "result" in json && json["result"]["code"] == 9) {
      if (errorCategory) {
        return runInAction(() => {
          errorsStore.errorMap.set(errorCategory, [json.result]);
          return Promise.reject(json);
        });
      }
    }
    return Promise.resolve(json);
  });
}
